export default class Task {
  constructor (
    {
      id,
      name,
      order = 0,
      createdAt = null,
      updatedAt = null,
      isClosed = false,
      isStarred = false
    } = {}
  ) {
    if (id === undefined) {
      throw new Error()
    }
    this.id = id
    this.name = name
    this.order = order
    this.isClosed = isClosed || false
    this.isStarred = isStarred || false
    const now = new Date().toISOString()
    this.createdAt = createdAt || now
    this.updatedAt = updatedAt || now
  }

  toggle () {
    this.isClosed = !this.isClosed
    this.updatedAt = new Date().toISOString()
  }

  updateName (text) {
    this.name = text
    this.updatedAt = new Date().toISOString()
  }

  updateOrder (order) {
    this.order = order
  }

  toggleStar () {
    this.isStarred = !this.isStarred
    this.updatedAt = new Date().toISOString()
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      order: this.order,
      isClosed: this.isClosed,
      isStarred: this.isStarred,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt
    }
  }
}
