import { v4 as uuidv4 } from 'uuid'
import Tag from '@/models/tag.js'
import Task from '@/models/task.js'
import TaskTag from '../models/taskTag'
import TaskStatus from '../models/taskStatus'

class ValidationError extends Error {}

export class TaskService {
  constructor (
    statusRepository,
    tagRepository,
    taskRepository,
    taskStatusRepository,
    taskTagRepository
  ) {
    this.statusRepository = statusRepository
    this.tagRepository = tagRepository
    this.taskRepository = taskRepository
    this.taskStatusRepository = taskStatusRepository
    this.taskTagRepository = taskTagRepository
  }

  async calculateNewOrder () {
    const tasks = await this.taskRepository.getAll()
    if (tasks.length === 0) {
      return 1
    } else {
      const orders = tasks.map(x => x.order).filter(x => x != null)
      const orderMax = Math.max(...orders)
      return orderMax + 1
    }
  }

  async addTask (taskName, tagNames) {
    const newOrder = await this.calculateNewOrder()
    const t = new Task(
      {
        id: uuidv4(),
        name: taskName,
        order: newOrder
      }
    )
    await this.taskRepository.add(t.toJSON())
    const tagIds = []
    const newTagNames = Tag.parseTagsString(tagNames)
    const allTags = await this.tagRepository.getAll()
    // TODO: raise an error if there are multiple status enabled tags
    for (const tagName of newTagNames) {
      if (Tag.exists(tagName, allTags)) {
        const tagId = Tag.getTagId(tagName, allTags)
        tagIds.push(tagId)
        const tag = await this.tagRepository.get(tagId)
        if (tag.useStatuses) {
          const statuses = await this.statusRepository.getAll()
          const statusesByTagId = statuses.filter(x => x.tagId === tagId)
          const open = statusesByTagId.find(x => x.prevStatusId === null)
          const taskStatus = new TaskStatus(
            {
              id: uuidv4(),
              taskId: t.id,
              statusId: open.id
            }
          )
          await this.taskStatusRepository.add(taskStatus.toJSON())
        }
      } else {
        const newTag = new Tag(
          {
            id: uuidv4(),
            name: tagName
          }
        )
        tagIds.push(newTag.id)
        await this.tagRepository.add(newTag.toJSON())
      }
    }
    for (const tagId of tagIds) {
      const newTaskTag = new TaskTag(
        uuidv4(),
        t.id,
        tagId
      )
      await this.taskTagRepository.add(newTaskTag.toJSON())
    }
    const allTags2 = await this.tagRepository.getAll()
    const tags = allTags2.filter(x => tagIds.includes(x.id))
    const result = t.toJSON()
    result.tags = tags
    return result
  }

  async calcNewOrder (draggedTask, droppedTask) {
    const tasks = await this.taskRepository.getAll()
    const sorted = tasks.sort((a, b) => { return (a.order > b.order) ? 1 : -1 })
    const taskFirst = sorted[0]
    const taskLast = sorted[sorted.length - 1]
    const indexDroppedTask = sorted.findIndex(x => x.id === droppedTask.id)
    if (droppedTask.id === taskFirst.id) {
      return taskFirst.order - 1
    } else if (droppedTask.id === taskLast.id) {
      return taskLast.order + 1
    } else if (draggedTask.order < droppedTask.order) {
      const newOrder = (droppedTask.order + sorted[indexDroppedTask + 1].order) / 2
      return newOrder
    } else if (droppedTask.order < draggedTask.order) {
      const newOrder = (droppedTask.order + sorted[indexDroppedTask - 1].order) / 2
      return newOrder
    } else {
      console.log('calc error', draggedTask, droppedTask)
    }
  }

  async clear () {
    const result = await this.taskRepository.clear()
    await this.taskStatusRepository.clear()
    return result
  }

  async updateTaskName (task, newName) {
    if (newName === '') {
      throw new ValidationError()
    }
    const t = new Task(task)
    t.updateName(newName)
    await this.taskRepository.put(t.toJSON())
  }

  async updateTaskOrder (task, newOrder) {
    const t = new Task(task)
    t.updateOrder(newOrder)
    await this.taskRepository.put(t.toJSON())
  }

  async addTags (addedTagNames, hasStatusesEnabledTag) {
    const addedTagIds = []
    const allTags = await this.tagRepository.getAll()
    for (const tagName of addedTagNames) {
      if (Tag.exists(tagName, allTags)) {
        const tagId = Tag.getTagId(tagName, allTags)
        const tag = await this.tagRepository.get(tagId)
        if (tag.useStatuses === true && hasStatusesEnabledTag) {
          console.log('failed to add two status enabled tags')
          throw new Error()
        }
        addedTagIds.push(tagId)
      } else {
        const newTag = new Tag(
          {
            id: uuidv4(),
            name: tagName
          }
        )
        addedTagIds.push(newTag.id)
        await this.tagRepository.add(newTag.toJSON())
      }
    }
    return addedTagIds
  }

  async addTaskTags (taskId, addedTagIds) {
    for (const tagId of addedTagIds) {
      const newTaskTag = new TaskTag(
        uuidv4(),
        taskId,
        tagId
      )
      await this.taskTagRepository.add(newTaskTag.toJSON())
    }
  }

  async deleteTaskTags (deletedTaskTagIds) {
    for (const taskTagId of deletedTaskTagIds) {
      await this.taskTagRepository.delete(taskTagId)
    }
  }

  async deleteUnusedTags (deletedTagIds) {
    for (const tagId of deletedTagIds) {
      const allTaskTags = await this.taskTagRepository.getAll()
      const taskTags = allTaskTags.filter(x => x.tagId === tagId)
      if (taskTags.length === 0) {
        await this.tagRepository.delete(tagId)
        const statusesAll = await this.statusRepository.getAll()
        const statuses = statusesAll.filter(x => x.tagId === tagId)
        for (const status of statuses) {
          await this.statusRepository.delete(status.id)
          const taskStatusesAll = await this.statusRepository.getAll()
          const taskStatuses = taskStatusesAll.filter(x => x.statusId === status.id)
          for (const taskStatus of taskStatuses) {
            await this.taskStatusRepository.delete(taskStatus.id)
          }
        }
      }
    }
  }

  async updateTags (task, tagNames) {
    // TODO: add or delete a status
    // TODO: delete statuses when delete status enabled tag
    const originalTagNames = task.tags.map(x => x.name)
    const newTagNames = Tag.parseTagsString(tagNames)
    const addedTagNames = newTagNames.filter(x => !originalTagNames.includes(x))
    const deletedTagNames = originalTagNames.filter(x => !newTagNames.includes(x))
    const allTaskTags = await this.taskTagRepository.getAll()
    const taskTags = allTaskTags.filter(x => x.taskId === task.id)
    const allTags = await this.tagRepository.getAll()
    const deletedTagIds = deletedTagNames.map(x => Tag.getTagId(x, allTags))
    const deletedTaskTagIds = taskTags.filter(x => deletedTagIds.includes(x.tagId)).map(x => x.id)
    await this.deleteTaskTags(deletedTaskTagIds)
    await this.deleteUnusedTags(deletedTagIds)
    const allTaskTags4 = await this.taskTagRepository.getAll()
    const taskTags4 = allTaskTags4.filter(x => x.taskId === task.id)
    const tagIds4 = taskTags4.map(x => x.tagId)
    const allTags4 = await this.tagRepository.getAll()
    const statusesEnabledTags = allTags4.filter(x => tagIds4.includes(x.id)).filter(x => x.useStatuses === true)
    const hasStatusesEnabledTag = (statusesEnabledTags.length !== 0)
    const addedTagIds = await this.addTags(addedTagNames, hasStatusesEnabledTag)
    await this.addTaskTags(task.id, addedTagIds)
    const allTaskTags2 = await this.taskTagRepository.getAll()
    const taskTags2 = allTaskTags2.filter(x => x.taskId === task.id)
    const tagIds2 = taskTags2.map(x => x.tagId)
    const allTags3 = await this.tagRepository.getAll()
    const tags = allTags3.filter(x => tagIds2.includes(x.id))
    const result = task
    result.tags = tags
    return result
  }

  async closeTask (task) {
    const t = new Task(task)
    t.toggle()
    await this.taskRepository.put(t.toJSON())
    const taskStatuses = await this.taskStatusRepository.getAll()
    const taskStatusObj = taskStatuses.find(x => x.taskId === task.id)
    let newStatus
    if (taskStatusObj) {
      const taskStatus = new TaskStatus(taskStatusObj)
      const status = await this.statusRepository.get(taskStatus.statusId)
      const statusesAll = await this.statusRepository.getAll()
      const statuses = statusesAll.filter(x => x.tagId === status.tagId)
      newStatus = t.isClosed ? statuses.find(x => x.nextStatusId === null) : statuses.find(x => x.prevStatusId === null)
      taskStatus.updateStatusId(newStatus.id)
      await this.taskStatusRepository.put(taskStatus)
    }
    return { isClosed: t.isClosed, status: newStatus }
  }

  async starTask (task) {
    const t = new Task(task)
    t.toggleStar()
    await this.taskRepository.put(t.toJSON())
  }

  async deleteTask (id) {
    // TODO: delete statuses when delete a tag
    await this.taskRepository.delete(id)
    const taskTagsAll = await this.taskTagRepository.getAll()
    const taskTags = taskTagsAll.filter(x => x.taskId === id)
    for (const taskTag of taskTags) {
      await this.taskTagRepository.delete(taskTag.id)
    }
    const tagIds = taskTags.map(x => x.tagId)
    for (const tagId of tagIds) {
      const taskTagsAll = await this.taskTagRepository.getAll()
      const taskTags = taskTagsAll.filter(x => x.tagId === tagId)
      if (taskTags.length === 0) {
        await this.tagRepository.delete(tagId)
      }
    }
    const taskStatusesAll = await this.taskStatusRepository.getAll()
    const taskStatuses = taskStatusesAll.filter(x => x.taskId === id)
    for (const ts of taskStatuses) {
      await this.taskStatusRepository.delete(ts.id)
    }
  }

  async addInitialCustomStatuses (tagId, openStatusId, closedStatusId) {
    const tasks = await this.taskRepository.getAll()
    const taskTags = await this.taskTagRepository.getAll()
    const taskIds = taskTags.filter(x => x.tagId === tagId).map(x => x.taskId)
    const openTasks = tasks.filter(x => taskIds.includes(x.id)).filter(x => !x.isClosed)
    for (const task of openTasks) {
      const ts = new TaskStatus(
        {
          id: uuidv4(),
          taskId: task.id,
          statusId: openStatusId
        }
      )
      await this.taskStatusRepository.add(ts.toJSON())
    }
    const closedTasks = tasks.filter(x => taskIds.includes(x.id)).filter(x => x.isClosed)
    for (const task of closedTasks) {
      const ts = new TaskStatus(
        {
          id: uuidv4(),
          taskId: task.id,
          statusId: closedStatusId
        }
      )
      await this.taskStatusRepository.add(ts.toJSON())
    }
  }

  async deleteCustomStatuses (tagId) {
    const taskTags = await this.taskTagRepository.getAll()
    const taskIds = taskTags.filter(x => x.tagId === tagId).map(x => x.taskId)
    const taskStatuses = await this.taskStatusRepository.getAll()
    const targetTaskStatuses = taskStatuses.filter(x => taskIds.includes(x.taskId))
    for (const taskStatus of targetTaskStatuses) {
      await this.taskStatusRepository.delete(taskStatus.id)
    }
  }

  async getTaskStatuses () {
    const taskStatuses = await this.taskStatusRepository.getAll()
    return taskStatuses
  }

  async getTaskTags () {
    const taskTags = await this.taskTagRepository.getAll()
    return taskTags
  }

  async updateStatus (task, statusId) {
    const taskStatuses = await this.taskStatusRepository.getAll()
    const taskStatus = new TaskStatus(taskStatuses.find(x => x.taskId === task.id))
    taskStatus.updateStatusId(statusId)
    const taskStatusUpdated = await this.taskStatusRepository.put(taskStatus.toJSON())
    const status = await this.statusRepository.get(statusId)
    if (status.nextStatusId === null && task.isClosed === false) {
      const t = new Task(task)
      t.toggle()
      await this.taskRepository.put(t.toJSON())
    } else if (status.nextStatusId !== null && task.isClosed === true) {
      const t = new Task(task)
      t.toggle()
      await this.taskRepository.put(t.toJSON())
    }
    return taskStatusUpdated
  }

  async get (id) {
    const task = await this.taskRepository.get(id)
    return task
  }

  async getAll () {
    const tasks = await this.taskRepository.getAll()
    return tasks
  }
}
