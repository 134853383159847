<template>
  <div class="sandbox">
    <h1>Sandbox</h1>
    <ul>
      <li
        draggable="true"
        v-for="item in items.sort((a, b) => { return (a.order > b.order) ? 1 : -1 })"
        :key="item.id"
        @dragend="dragend($event)"
        @dragenter="dragenter($event, item)"
        @dragstart="dragstart($event, item)"
        @dragover="dragover($event)"
        @dragleave="dragleave($event)"
        @drop="dropItem($event, item)"
      >
        {{ item.name }}
      </li>
    </ul>
    <hr>
    <ul class="list-style-none pl-0" style="border: 1px solid #ccc;">
      <li class="h-8" style="">
        <AppLink text="List" to="#" class="block hover-blue"></AppLink>
      </li>
      <li class="h-8" style="">
        <AppLink text="List" to="#" class="block hover-blue"></AppLink>
      </li>
      <li class="h-8" style="">
        <router-link to="/#" class="block hover-blue h-8">
          <div class="" style="">
            <span class="block" style="padding-top: 0.5rem; padding-bottom: 0.5rem; padding-left: 1rem; padding-right: 1rem;">List</span>
          </div>
        </router-link>
      </li>
    </ul>
    <hr>
    <div style="background-color:#ffcdd2;">tag1</div>
    <div style="background-color:#e1bee7;">tag2</div>
    <div style="background-color:#bbdefb;">tag3</div>
    <span style="background-color:#ffcdd2;">tag1</span>
    <span style="background-color:#e1bee7;">tag2</span>
    <span style="background-color:#bbdefb;">tag3</span>
    <span class="px-1" style="background-color:#ffcdd2;">tag1</span>
    <span class="px-1" style="background-color:#e1bee7;">tag2</span>
    <span class="px-1" style="background-color:#bbdefb;">tag3</span>
    <span class="p-1" style="background-color:#ffcdd2;">tag1</span>
    <span class="p-1" style="background-color:#e1bee7;">tag2</span>
    <span class="p-1" style="background-color:#bbdefb;">tag3</span>
    <span class="px-2 py-1" style="background-color:#ffcdd2;">tag1</span>
    <span class="px-2 py-1" style="background-color:#e1bee7;">tag2</span>
    <span class="px-2 py-1" style="background-color:#bbdefb;">tag3</span>
</div>
</template>

<script>
import { toRaw } from 'vue'
import AppLink from '@/components/AppLink'

export default {
  name: 'Sandbox',
  components: {
    AppLink
  },
  data () {
    return {
      items: [
        {
          id: 1,
          name: 'list 1',
          order: 1
        },
        {
          id: 2,
          name: 'list 2',
          order: 2
        },
        {
          id: 3,
          name: 'list 3',
          order: 3
        },
        {
          id: 4,
          name: 'list 4',
          order: 4
        },
        {
          id: 5,
          name: 'list 5',
          order: 5
        },
        {
          id: 6,
          name: 'list 6',
          order: 6
        },
        {
          id: 7,
          name: 'list 7',
          order: 7
        }
      ],
      draggedId: '',
      dropId: ''
    }
  },
  methods: {
    dragleave: function (e) {
      if (e.target.nodeType === 3) return
      if (e.relatedTarget) {
        if (e.relatedTarget.nodeType === 3) return
      }
      console.log('dragleave', e)
      if (e.target.style) {
        e.target.style.backgroundColor = ''
        e.target.style.opacity = ''
      }
    },
    dragend: function (e) {
      console.log('dragend', e)
      e.target.style.opacity = ''
    },
    dragenter: function (e, item) {
      if (e.target.nodeType === 3) return
      if (e.relatedTarget) {
        if (e.relatedTarget.nodeType === 3) return
      }
      console.log('dragenter', e)
      if (e.target.style) {
        e.target.style.backgroundColor = '#eeeeee'
        e.target.style.opacity = 0.5
      }
      this.dropId = toRaw(item).id
      console.log('dropId', this.dropId)
    },
    dragover: function (e) {
      e.preventDefault()
    },
    dragstart: function (e, item) {
      console.log('dragstart', e, toRaw(item))
      e.target.style.opacity = 0.5
      this.draggedId = toRaw(item).id
    },
    dropItem: function (e, item) {
      console.log('drop', e, toRaw(item))
      e.target.style.backgroundColor = ''
      e.target.style.opacity = ''
      this.reorder()
      this.draggedId = ''
    },
    reorder: function () {
      console.log('reorder')
      const idFirst = this.items[0].id
      const idLast = this.items[this.items.length - 1].id
      const itemsRaw = toRaw(this.items)
      const itemIndex = itemsRaw.findIndex(x => x.id === this.draggedId)
      const itemIndexDropped = itemsRaw.findIndex(x => x.id === this.dropId)
      const itemDragged = itemsRaw.find(x => x.id === this.draggedId)
      const itemDropped = itemsRaw.find(x => x.id === this.dropId)
      if (this.dropId === idFirst) {
        const itemFirst = this.items.find(x => x.id === idFirst)
        this.items[itemIndex].order = itemFirst.order - 1
      } else if (this.dropId === idLast) {
        const itemLast = this.items.find(x => x.id === idLast)
        this.items[itemIndex].order = itemLast.order + 1
      } else if (itemDragged.order < itemDropped.order) {
        const newOrder = (itemsRaw[itemIndexDropped].order + itemsRaw[itemIndexDropped + 1].order) / 2
        this.items[itemIndex].order = newOrder
      } else if (itemDropped.order < itemDragged.order) {
        const newOrder = (itemsRaw[itemIndexDropped].order + itemsRaw[itemIndexDropped - 1].order) / 2
        this.items[itemIndex].order = newOrder
      } else {
        console.log('error')
      }
    }
  }
}
</script>

<style scoped>
.sandbox {
  text-align: left;
}
</style>
