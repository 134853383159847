<script setup>
import AppButton from '@/components/AppButton'

const clear = async function () {
  if (window.confirm('Do you really want to delete all data?')) {
    await this.$store.dispatch('tasks/clearTasks')
  }
}
const reload = function () {
  location.reload(true)
}
</script>

<template>
  <div class="text-left">
    <div class="layout-center">
      <div class="m-2">
        <h1 class="text-xlarge mb-6">Settings</h1>
        <div class="layout-stack">
          <div>
            <AppButton
              text="clear all data"
              @click="clear()"
            />
          </div>
          <div>
            <AppButton
              text="reload"
              @click="reload()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.settings {
}
</style>
