<script setup>
import AppButtonDropdown from '@/components/AppButtonDropdown'
import AppInputText from '@/components/AppInputText'

const props = defineProps({
  newTaskName: {
    type: String
  },
  newTaskTags: {
    type: String
  },
  open: {
    type: Boolean
  }
})
const emit = defineEmits(['changeTaskName', 'changeTaskTags', 'emitAddTask', 'clickDelete', 'emitToggle'])
const handleClickDelete = (event) => {
  emit('clickDelete')
  emit('emitToggle', event)
}
</script>

<template>
  <div class="flex-row h-8">
    <div class="f-3">
      <AppInputText
        autofocus
        :maxlength="128"
        placeholder="Add task"
        ref="newTaskForm"
        :value="props.newTaskName"
        @changeText="emit('changeTaskName', $event)"
        @enter="emit('emitAddTask')"
      />
    </div>
    <div class="f-1">
      <AppInputText
        placeholder="Tags"
        :value="props.newTaskTags"
        @changeText="emit('changeTaskTags', $event)"
        @enter="emit('emitAddTask')"
      />
    </div>
    <div class="w-8 h-8"></div>
    <div class="w-8 h-8 text-right">
      <AppButtonDropdown
        text="⋮"
        @emitToggle="emit('emitToggle', $event)"
        :open="props.open"
      >
        <ul
          class="pl-0 list-style-none border-1 my-0 cursor-pointer bg-color-secondary"
          style="position: relative; top: 0.5rem; left: -10rem; width: 12rem; z-index: 100;"
        >
          <li class="h-8 flex-column">
            <span
              class="hover block h-8 py-2 px-4"
              @click="handleClickDelete($event)"
            >
              Delete closed tasks
            </span>
          </li>
        </ul>
      </AppButtonDropdown>
    </div>
  </div>
</template>

<style scoped>
</style>
