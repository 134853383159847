<script setup>
import { toRaw } from 'vue'
import { mapGetters } from 'vuex'
import { useRoute } from 'vue-router'
import TagList from '@/components/TagList'
import TaskBoard from '@/components/TaskBoard'
import TaskDashboard from '@/components/TaskDashboard'
import TaskForm from '@/components/TaskForm'
import TaskList from '@/components/TaskList'
import TaskListWithStatus from '@/components/TaskListWithStatus'
import TheLocalNav from '@/components/TheLocalNav'
import { state } from '@/state'

const route = useRoute()
</script>

<template>
  <div class="flex-row" style="height: calc(100vh - 4.5rem);">
    <div class="homeLeft m-4 max-w-40ch">
      <TaskDashboard
        :tasksCount="state.tasks.length"
        :tasksClosedCount="state.tasks.filter(x => x.isClosed).length"
      />
    </div>
    <div class="homeCenter mx-4 mt-4 px-2 layout-center flex-column overflow-hidden">
      <div class="center-top text-right mb-2">
        <div>
          <small
            class="text-secondary"
            v-if="platform() === 'macOS'"
          >
            "cmd + k": open command menu
          </small>
          <small
            class="text-secondary"
            v-else
          >
            "control + k": open command menu
          </small>
        </div>
      </div>
      <div>
        <TheLocalNav
          :currentFilter="filter"
          :currentTag="route.query.tag"
          :currentView="route.query.view"
          :tags="state.tagsSorted"
          :selectedTagId="selectedTagId"
        />
      </div>
      <div>
        <TaskForm
          class="my-4"
          :newTaskName="newTaskName"
          :newTaskTags="newTaskTags"
          :open="dropdownOpen"
          @emitAddTask="addTask"
          @changeTaskName="newTaskName = $event"
          @changeTaskTags="newTaskTags = $event"
          @clickDelete="deleteClosedTasks($event)"
          @emitToggle="toggleDropdown($event)"
        />
      </div>
      <TaskList
        v-if="route.query.view === undefined"
        :tasks="getTasksByConditions"
        :bulkChecked="bulkChecked"
        @clickCheckbox="closeTask($event)"
        @clickDelete="deleteTask($event)"
        @clickStar="starTask($event)"
        @clickBulkCheck="bulkCheck($event)"
        @enter="updateTaskName($event)"
        @enterTags="updateTaskTags($event)"
        @reorder="updateTaskOrder($event)"
        @updateDraggedTask="updateDraggedTask($event)"
      />
      <TaskListWithStatus
        v-else-if="route.query.view === 'status-list'"
        :statuses="getStatusesByTagId(selectedTagId)"
        :tasks="getTasksWithStatusByTagId"
        @changeStatus="changeStatus($event)"
        @clickDelete="deleteTask($event)"
        @clickStar="starTask($event)"
        @enter="updateTaskName($event)"
        @enterTags="updateTaskTags($event)"
        @reorder="updateTaskOrder($event)"
        @updateDraggedTask="updateDraggedTask($event)"
      />
      <TaskBoard
        v-else-if="route.query.view === 'board'"
        :statuses="getStatusesByTagId(selectedTagId)"
        :tasks="getTasksWithStatusByTagId"
        @clickPrev="clickPrev($event)"
        @clickNext="clickNext($event)"
        @clickStar="starTask($event)"
        @enter="updateTaskName($event)"
        @reorder="updateTaskOrder($event)"
        @updateDraggedTask="updateDraggedTask($event)"
      />
      <div v-else-if="route.query.view === 'timeline'">timeline</div>
      <div v-else>not implemented</div>
    </div>
    <div class="homeRight m-4 max-w-40ch overflow-x-hidden ptn-scrollbar-thin pr-2">
      <TagList
        :tags="tagsWithCount"
        :currentTag="tag"
      />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    ...mapGetters(
      'tasks',
      [
        'getTasks',
        'getTasksByConditions',
        'getTasksWithStatusByTagId'
      ]
    ),
    ...mapGetters(
      'statuses',
      [
        'getStatusesByTagId'
      ]
    ),
    ...mapGetters(
      'taskTags',
      [
        'getTaskTags'
      ]
    ),
    tagsWithCount () {
      const taskTags = this.$store.state.taskTags.all
      const tags = this.$store.state.tags.all
      const result = []
      for (const tag of tags) {
        const count = taskTags.filter(x => x.tagId === tag.id).length
        result.push({ id: tag.id, name: tag.name, count: count, useStatuses: tag.useStatuses, isPinned: tag.isPinned })
      }
      return result.sort((a, b) => { return (a.name > b.name) ? 1 : -1 }).sort(a => a.isPinned ? -1 : 1)
    }
  },
  data () {
    return {
      newTaskName: '',
      newTaskTags: '',
      bulkChecked: false,
      dialogOpened: false,
      draggedTask: {},
      filter: '',
      tag: '',
      selectedTagId: '',
      dropdownOpen: false
    }
  },
  created: async function () {
    await this.$store.dispatch('connectDB')
    await this.$store.dispatch('tags/load')
    await this.$store.dispatch('taskTags/load')
    await this.$store.dispatch('tasks/load')
    await this.$store.dispatch('statuses/load')
    const tag = this.$route.query.tag
    if (tag) {
      this.tag = tag
      this.newTaskTags = tag
    }
    const filter = this.$route.query.filter
    this.filter = filter
    if (!filter) {
      if (tag) {
        this.filter = ''
      } else {
        this.filter = 'all'
      }
    }
    const selectedTag = state.tags.find(x => x.name === this.$route.query.tag)
    this.selectedTagId = selectedTag ? selectedTag.id : ''
  },
  watch: {
    $route (to, from) {
      const tag = this.$route.query.tag
      if (tag) {
        this.newTaskTags = tag
        this.tag = tag
      } else {
        this.newTaskTags = ''
        this.tag = ''
      }
      const filter = this.$route.query.filter
      this.filter = filter
      if (!filter) {
        if (tag) {
          this.filter = ''
        } else {
          this.filter = 'all'
        }
      } else {
        this.tag = ''
      }
      const selectedTag = state.tags.find(x => x.name === this.$route.query.tag)
      this.selectedTagId = selectedTag ? selectedTag.id : ''
      this.bulkChecked = false
    }
  },
  methods: {
    addTask: async function () {
      if (this.newTaskName === '') return
      await this.$store.dispatch('tasks/addTask', { taskName: this.newTaskName, tagNames: this.newTaskTags })
      // const newTaskElem = this.$refs.newTaskForm.$el
      // newTaskElem.focus()
      this.newTaskName = ''
      this.newTaskTags = ''
      const tag = this.$route.query.tag
      if (tag) {
        this.newTaskTags = tag
      }
      this.bulkChecked = false
      await state.load() // TODO
    },
    clickPrev: async function (task) {
      await this.$store.dispatch('tasks/updateStatus', { task: task, statusId: task.status.prevStatusId })
      await state.load() // TODO
    },
    clickNext: async function (task) {
      await this.$store.dispatch('tasks/updateStatus', { task: task, statusId: task.status.nextStatusId })
      await state.load() // TODO
    },
    deleteTask: async function (task) {
      await this.$store.dispatch('tasks/deleteTask', task)
      await state.load() // TODO
    },
    deleteClosedTasks: async function () {
      if (window.confirm('Do you really want to delete closed tasks?')) {
        const tasks = await toRaw(this.getTasks).filter(x => x.isClosed === true)
        const ids = tasks.map(x => x.id)
        await this.$store.dispatch('tasks/deleteTasks', ids)
        for (const taskId of ids) {
          const taskTagsAll = await toRaw(this.getTaskTags)
          const taskTags = taskTagsAll.filter(x => x.taskId === taskId)
          for (const taskTag of taskTags) {
            await this.$store.dispatch('taskTags/deleteTaskTag', taskTag)
          }
          const tagIds = taskTags.map(x => x.tagId)
          for (const tagId of tagIds) {
            const taskTags = taskTagsAll.filter(x => x.tagId === tagId)
            if (taskTags.length === 0) {
              await this.$store.dispatch('tags/deleteTag', tagId)
            }
          }
        }
        this.bulkChecked = false
      }
      await state.load() // TODO
    },
    toggleDropdown: function (event) {
      this.dropdownOpen = event.target.open
    },
    changeStatus: async function (params) {
      await this.$store.dispatch('tasks/updateStatus', { task: params.task, statusId: params.statusId })
      await state.load() // TODO
    },
    closeTask: async function (task) {
      await this.$store.dispatch('tasks/closeTask', task)
      await state.load() // TODO
    },
    starTask: async function (task) {
      await this.$store.dispatch('tasks/starTask', task)
    },
    updateDraggedTask: function (payload) {
      this.draggedTask = toRaw(payload)
    },
    updateTaskName: async function (payload) {
      if (payload.$event.keyCode === 13) {
        const newName = payload.$event.target.textContent
        await this.$store.dispatch('tasks/updateTaskName', { task: payload.task, newName: newName })
        payload.$event.target.blur()
      }
    },
    updateTaskOrder: async function (payload) {
      await this.$store.dispatch('tasks/updateTaskOrder', { task: this.draggedTask, taskDropped: payload })
      this.draggedTask = {}
    },
    updateTaskTags: async function (payload) {
      if (payload.$event.keyCode === 13) {
        const newTagNames = payload.$event.target.textContent
        if (newTagNames !== '-') {
          await this.$store.dispatch('tasks/updateTags', { task: payload.task, newTagNames: newTagNames })
        }
        await state.load() // TODO
        payload.$event.target.blur()
      }
    },
    bulkCheck: async function (e) {
      this.bulkChecked = e.target.checked
      const tasks = this.getTasksByConditions
      const tasksTarget = tasks.filter(x => x.isClosed === !this.bulkChecked)
      for (const task of tasksTarget) {
        await this.$store.dispatch('tasks/closeTask', task)
      }
      await state.load() // TODO
    },
    platform: function () {
      if (navigator.userAgentData) {
        return navigator.userAgentData.platform
      } else {
        const userAgent = navigator.userAgent
        if (userAgent.includes('Macintosh')) {
          return 'macOS'
        } else {
          return 'theOtherOS'
        }
      }
    }
  }
}
</script>

<style scoped>
.homeLeft {
  display: none;
}
.homeCenter {
  flex: 12;
  text-align: left;
}
.center-top {
  display: none;
}
.homeRight {
  display: none;
}
@media screen and (min-width: 48rem) {
  .homeLeft {
    display: flex;
    flex: 2;
    flex-direction: column;
  }
  .homeRight {
    display: flex;
    flex: 2;
  }
  .homeCenter {
  }
  .center-top {
    display: block;
  }
}
</style>
