import { v4 as uuidv4 } from 'uuid'
import Status from '@/models/status'
import Tag from '../models/tag'

export class TagService {
  constructor (
    tagRepository,
    statusRepository
  ) {
    this.tagRepository = tagRepository
    this.statusRepository = statusRepository
  }

  async add (tag) {
    const tagAdded = await this.tagRepository.add(tag)
    return tagAdded
  }

  async addInitialStatuses (tagId) {
    const openId = uuidv4()
    const closedId = uuidv4()
    const open = new Status(
      {
        id: openId,
        name: 'open',
        createdAt: null,
        updatedAt: null,
        tagId: tagId,
        prevStatusId: null,
        nextStatusId: closedId
      }
    )
    const closed = new Status(
      {
        id: closedId,
        name: 'closed',
        createdAt: null,
        updatedAt: null,
        tagId: tagId,
        prevStatusId: openId,
        nextStatusId: null
      }
    )
    await this.statusRepository.add(open.toJSON())
    await this.statusRepository.add(closed.toJSON())
    return [open, closed]
  }

  async clear () {
    const result = await this.tagRepository.clear()
    return result
  }

  async delete (tagId) {
    const result = await this.tagRepository.delete(tagId)
    return result
  }

  async deleteStatuses (tagId) {
    const statuses = await this.statusRepository.getAll()
    const targetStatuses = statuses.filter(x => x.tagId === tagId)
    for (const status of targetStatuses) {
      await this.statusRepository.delete(status.id)
    }
    return targetStatuses
  }

  async get (id) {
    const tag = await this.tagRepository.get(id)
    return tag
  }

  async getAll () {
    const tags = await this.tagRepository.getAll()
    return tags
  }

  async put (tag) {
    const result = await this.tagRepository.put(tag)
    return result
  }

  async toggleUseStatuses (tagId) {
    const tag = await this.tagRepository.get(tagId)
    const t = new Tag(tag)
    t.toggleUseStatuses()
    await this.tagRepository.put(t)
    return t
  }

  async togglePinned (tagId) {
    const tag = await this.tagRepository.get(tagId)
    const t = new Tag(tag)
    t.togglePinned()
    await this.tagRepository.put(t)
    return t
  }
}
