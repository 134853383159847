<script setup>
import AppIconClear from '@/components/AppIconClear'
import AppIconDragIndicator from '@/components/AppIconDragIndicator'
import AppIconStar from '@/components/AppIconStar'
import AppIconStarOutline from '@/components/AppIconStarOutline'
import AppSelect from '@/components/AppSelect'

defineProps({
  statuses: {
    type: Array
  },
  task: {
    type: Object
  }
})
const emit = defineEmits(
  [
    'changeStatus',
    'clickDelete',
    'clickStar',
    'enter',
    'enterTags',
    'updateDraggedTask',
    'reorder'
  ]
)
const dragend = (e) => {
  e.target.style.opacity = ''
}
const dragover = (e) => {
  e.preventDefault()
}
const dragstart = (e, task) => {
  e.target.style.opacity = 0.5
  emit('updateDraggedTask', task)
}
const drop = (task) => {
  emit('reorder', task)
}
</script>

<template>
  <div
    class="h-8 flex-row border-1 overflow-hidden pr-1 bg-color-secondary hover"
    :draggable="!task.isStarred"
    @dragend="dragend($event)"
    @dragover="dragover($event)"
    @dragstart="dragstart($event, task)"
    @drop="drop(task)"
  >
    <div class="f-10 flex-row overflow-hidden">
      <div class="w-4 h-8 flex-column">
        <AppIconDragIndicator
          v-if="task.isStarred"
          class="m-auto text-disabled"
          cursor="not-allowed"
          size="small"
        />
        <AppIconDragIndicator
          v-else
          class="m-auto text-secondary"
          cursor="grab"
          size="small"
        />
      </div>
      <div
        class="f-2 flex-column pr-2"
      >
        <AppSelect
          class="my-auto mx-1"
          :options="statuses"
          :selected="task.status.id"
          @changeValue="emit('changeStatus', $event)"
        />
      </div>
      <div class="f-6 overflow-hidden">
        <div
          class="my-2 nowrap overflow-hidden text-overflow-ellipsis"
          v-bind:class="{ 'text-line-through text-secondary': task.isClosed }"
          contenteditable="true"
          @keydown.enter.prevent="emit('enter', { task, $event })"
        >
          {{ task.name }}
        </div>
      </div>
      <div class="f-2 overflow-hidden">
        <div
          class="my-2 text-secondary nowrap overflow-hidden text-overflow-ellipsis"
          v-if="task.tags"
          contenteditable="true"
          @keydown.enter.prevent="emit('enterTags', { task, $event })"
        >
          {{ task.tags.map(x => x.name).join(',') }}
        </div>
      </div>
    </div>
    <div class="flex-row">
      <div class="w-6 h-8 text-center flex-column">
        <div class="m-auto">
          <AppIconStar
            v-if="task.isStarred"
            @click="emit('clickStar', task)"
            class="hover-text-blue"
          />
          <AppIconStarOutline
            v-else
            @click="emit('clickStar', task)"
            class="hover-text-blue"
          />
        </div>
      </div>
      <div class="w-6 h-8 text-center flex-column">
        <div class="m-auto">
          <AppIconClear
            @click="emit('clickDelete', task)"
            class="hover-text-blue"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
