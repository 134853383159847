import { TagService } from '@/services/tagService'
import { TagRepository } from '../../repositories/TagRepository'
import { StatusRepository } from '../../repositories/StatusRepository'

const state = {
  all: [
  ]
}

const getters = {
  getTags: state => {
    return state.all.sort((a, b) => { return (a.name > b.name) ? 1 : -1 })
  }
}

const mutations = {
  addTag (state, payload) {
    state.all.push(payload)
  },
  deleteTag (state, payload) {
    const index = state.all.map(x => x.id).indexOf(payload)
    state.all.splice(index, 1)
  },
  updateTag (state, payload) {
    const index = state.all.map(x => x.id).indexOf(payload.id)
    state.all[index] = payload
  },
  toggleUseStatuses (state, payload) {
    const index = state.all.map(x => x.id).indexOf(payload.id)
    state.all[index] = payload
  },
  togglePinned (state, payload) {
    const index = state.all.map(x => x.id).indexOf(payload.id)
    state.all[index] = payload
  },
  clearTags (state) {
    state.all = []
  },
  load (state, payload) {
    state.all = payload
  }
}

const actions = {
  async addTag ({ commit }, tag) {
    const tagService = new TagService(
      new TagRepository(),
      new StatusRepository()
    )
    const result = tagService.add(tag)
    commit('addTag', result)
  },
  async deleteTag ({ commit }, tagId) {
    const tagService = new TagService(
      new TagRepository(),
      new StatusRepository()
    )
    await tagService.delete(tagId)
    commit('deleteTag', tagId)
  },
  async updateTag ({ commit }, tag) {
    const tagService = new TagService(
      new TagRepository(),
      new StatusRepository()
    )
    await tagService.put(tag)
    commit('updateTag', tag)
  },
  async toggleUseStatuses ({ commit }, tagId) {
    const tagService = new TagService(
      new TagRepository(),
      new StatusRepository()
    )
    const result = await tagService.toggleUseStatuses(tagId)
    commit('toggleUseStatuses', result)
  },
  async togglePinned ({ commit }, tagId) {
    const tagService = new TagService(
      new TagRepository(),
      new StatusRepository()
    )
    const result = await tagService.togglePinned(tagId)
    commit('togglePinned', result)
  },
  async clearTags ({ commit }) {
    const tagService = new TagService(
      new TagRepository(),
      new StatusRepository()
    )
    await tagService.clear()
    commit('clearTags')
  },
  async load ({ commit }) {
    const tagService = new TagService(
      new TagRepository(),
      new StatusRepository()
    )
    const result = await tagService.getAll()
    commit('load', result)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
