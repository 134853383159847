<script setup>
import { reactive } from 'vue'
import AppButton from '@/components/AppButton'
import AppButtonDropdown from '@/components/AppButtonDropdown'
import AppButtonSquare from '@/components/AppButtonSquare'
import AppCheckbox from '@/components/AppCheckbox'
import AppDatetime from '@/components/AppDatetime'
import AppDetails from '@/components/AppDetails'
import AppIconClear from '@/components/AppIconClear'
import AppIconDarkMode from '@/components/AppIconDarkMode'
import AppIconDragIndicator from '@/components/AppIconDragIndicator'
import AppIconLightMode from '@/components/AppIconLightMode'
import AppIconMoreVert from '@/components/AppIconMoreVert'
import AppIconStar from '@/components/AppIconStar'
import AppIconStarOutline from '@/components/AppIconStarOutline'
import AppInputColor from '@/components/AppInputColor'
import AppInputSwitch from '@/components/AppInputSwitch'
import AppInputText from '@/components/AppInputText'
import AppLink from '@/components/AppLink'
import AppLinkLogo from '@/components/AppLinkLogo'
import AppParagraph from '@/components/AppParagraph'
import AppProgress from '@/components/AppProgress'
import AppTextLinkable from '@/components/AppTextLinkable'
import AppTextIcon from '@/components/AppTextIcon'
import CardItem from '@/components/CardItem'
import FilterList from '@/components/FilterList'
import HamburgerMenu from '@/components/HamburgerMenu'
import TaskBoard from '@/components/TaskBoard'
import TaskDashboard from '@/components/TaskDashboard'
import TaskForm from '@/components/TaskForm'
import TaskItem from '@/components/TaskItem'
import TaskList from '@/components/TaskList'
import ThemeSwitchButton from '@/components/ThemeSwitchButton'
import TheNavBar from '@/components/TheNavBar'
import ViewList from '@/components/ViewList'
import InputEmoji from '@/components/InputEmoji'

// emoji stuff
const data = reactive({ emoji: '🐱' })
const updateEmoji = () => {
  console.log('update emoji')
}

const statuses = [
  {
    id: '1',
    name: 'open'
  },
  {
    id: '2',
    name: 'doing'
  },
  {
    id: '3',
    name: 'testing'
  },
  {
    id: '4',
    name: 'closed'
  }
]

const taskForCard = {
  id: '1',
  name: 'buy milk',
  isClosed: false
}

const tasks = [
  {
    id: '1',
    name: 'buy milk',
    created: '',
    updated: '',
    tags: [
      {
        id: '1',
        name: 'buy',
        created: '',
        updated: ''
      }
    ],
    isClosed: false,
    isStarred: true
  },
  {
    id: '2',
    name: 'buy coffee',
    created: '',
    updated: '',
    tags: [
      {
        id: '1',
        name: 'buy',
        created: '',
        updated: ''
      }
    ],
    isClosed: true,
    isStarred: false
  },
  {
    id: '3',
    name: 'clean room',
    created: '',
    updated: '',
    tags: [
      {
        id: '2',
        name: 'clean',
        created: '',
        updated: ''
      }
    ],
    isClosed: false,
    isStarred: false
  }
]
const tasksForBoard = [
  {
    id: '1',
    name: 'task 1',
    status: {
      id: '1',
      name: 'open'
    }
  },
  {
    id: '2',
    name: 'task 2',
    status: {
      id: '1',
      name: 'open'
    }
  },
  {
    id: '3',
    name: 'task 3',
    status: {
      id: '2',
      name: 'doing'
    }
  },
  {
    id: '4',
    name: 'task 4',
    status: {
      id: '3',
      name: 'testing'
    }
  },
  {
    id: '5',
    name: 'task 5',
    status: {
      id: '4',
      name: 'closed'
    }
  },
  {
    id: '6',
    name: 'task 6',
    status: {
      id: '1',
      name: 'open'
    }
  }
]
</script>

<template>
  <div class="designSystem m-4">
    <h1>Design System</h1>
    <hr>
    <h2>Typography</h2>
    <h3>Paragraph</h3>
    <AppParagraph class="text-medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</AppParagraph>
    <AppParagraph class="text-medium secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</AppParagraph>
    <h3>List</h3>
    <ul class="text-medium">
      <li>Item</li>
      <li>Item</li>
      <li>Item</li>
      <li>Item</li>
      <li>Item</li>
    </ul>
    <h3>Size</h3>
    <div class="text-small">
      <span>Small</span>
    </div>
    <div class="text-medium">
      <span>Medium</span>
    </div>
    <div class="text-large">
      <span>Large</span>
    </div>
    <div class="text-xlarge">
      <span>XLarge</span>
    </div>
    <hr>
    <h2>Components</h2>
    <h3>Base</h3>
    <h4>AppButton</h4>
    <AppButton
      text="Button"
    />
    <AppButton
      text="Button"
      disabled="true"
    />
    <AppButton
      text="Button"
      appearance="text"
    />
    <AppButton
      text="Button"
      size="small"
    />
    <AppButton
      text="Button"
      size="small"
      disabled="true"
    />
    <AppButton
      text="Button"
      size="small"
      appearance="text"
    />
    <h4>AppButtonDropdown</h4>
    <div class="h-8">
      <AppButtonDropdown
        text="⋮"
      >
        <ul class="list-style-none pl-0 shadow-1 bg-color-white" style="position: relative; top: 0.5rem; z-index: 1;">
          <li class="h-8">
            <AppLink to="/#">
              <span>List</span>
            </AppLink>
          </li>
          <li class="h-8">
            <AppLink to="/#">
              <span>List</span>
            </AppLink>
          </li>
          <li class="h-8">
            <AppLink to="/#">
              <span>List</span>
            </AppLink>
          </li>
        </ul>
      </AppButtonDropdown>
    </div>
    <div class="flex-row w-60 h-8">
      <div class="f-1"></div>
      <div class="w-8">
        <AppButtonDropdown
          text="⋮"
        >
          <ul class="list-style-none pl-0 shadow-1 bg-color-white w-36" style="position: relative; top: 0.5rem; left: -7rem; z-index: 1;">
            <li class="h-8">
              <AppLink to="/#">
                <span>List List List</span>
              </AppLink>
            </li>
            <li class="h-8">
              <AppLink to="/#">
                <span>List</span>
              </AppLink>
            </li>
            <li class="h-8">
              <AppLink to="/#">
                <span>List List List List</span>
              </AppLink>
            </li>
          </ul>
        </AppButtonDropdown>
      </div>
    </div>
    <h4>AppButtonSquare</h4>
    <AppButtonSquare
      size="medium"
    />
    <AppButtonSquare
      size="small"
    />
    <h4>AppCheckbox</h4>
    <AppCheckbox
    />
    <AppCheckbox
      :isChecked="true"
    />
    <h4>AppDatetime</h4>
    <AppDatetime
      text=""
    />
    <h4>AppIcon</h4>
    <AppIconClear />
    <AppIconDarkMode />
    <AppIconDragIndicator />
    <AppIconLightMode />
    <AppIconMoreVert />
    <AppIconStar />
    <AppIconStarOutline />
    <h4>AppInputColor</h4>
    <AppInputColor />
    <h4>AppInputSwitch</h4>
    <AppInputSwitch :isChecked="false" />
    <AppInputSwitch :isChecked="true" />
    <h4>AppInputText</h4>
    <AppInputText
      size="medium"
    />
    <AppInputText
      size="small"
    />
    <h4>AppLink</h4>
    <AppLink
      text="Link"
      to="/#"
    />
    <AppLink
      text="Link"
      to="/#"
      appearance="secondary"
    />
    <AppLink
      text="Link"
      :isDisabled="true"
    />
    <hr>
    <AppLink
      to="/#"
    >
      <div class="h-8 hover-blue flex-column">
        <span class="px-2 my-auto">Link</span>
      </div>
    </AppLink>
    <AppLink
      to="/#"
    >
      <div class="h-8 hover-blue flex-column">
        <span class="px-2 my-auto">Link</span>
      </div>
    </AppLink>
    <AppLink
      to="/#"
    >
      <div class="h-8 hover-blue flex-column">
        <span class="px-2 my-auto">Link</span>
      </div>
    </AppLink>
    <hr class="m-0">
    <div>
      <AppLink
        to="/#"
      >
        <div class="h-8 hover-blue inline-block">
          <span class="p-2">Link</span>
        </div>
      </AppLink>
      <AppLink
        to="/#"
      >
        <div class="h-8 hover-blue inline-block">
          <span class="p-2">Link</span>
        </div>
      </AppLink>
      <AppLink
        to="/#"
      >
        <div class="h-8 hover-blue inline-block">
          <span class="p-2">Link</span>
        </div>
      </AppLink>
    </div>
    <h4>AppLinkLogo</h4>
    <AppLinkLogo
      text="⚡️Rydeen"
      to="/#"
    />
    <h4>AppProgress</h4>
    <AppProgress
      :value="3"
      :max="10"
    />
    <h4>AppTextLinkable</h4>
    <AppTextLinkable
      text="google https://www.google.com/"
    />
    <AppTextLinkable
      text="https://www.apple.com/ apple"
    />
    <h4>AppTextIcon</h4>
    <AppTextIcon
      text="☆"
    />
    <AppTextIcon
      text="★"
    />
    <AppTextIcon
      text="x"
    />
    <h4>AppDetails</h4>
    <AppDetails :open="false" />
    <AppDetails :open="true" />
    <h3>Advanced</h3>
    <h4>CardItem</h4>
    <CardItem :task="taskForCard" />
    <h4>InputEmoji</h4>
    <InputEmoji
      @changeEmoji="updateEmoji"
    />
    <InputEmoji
      :unicode="data.emoji"
      @changeEmoji="data.emoji = $event.unicode"
    />
    <h4>TaskBoard</h4>
    <TaskBoard
      :statuses="statuses"
      :tasks="tasksForBoard"
    />
    <h4>FilterList</h4>
    <FilterList />
    <h4>TagList</h4>
    <!-- <TagList
      :tags="tags"
    /> -->
    <h4>TaskDashboard</h4>
    <TaskDashboard
      :tasksCount="20"
      :tasksClosedCount="3"
    />
    <h4>TaskForm (WIP)</h4>
    <TaskForm />
    <TaskForm
      newTaskName="buy beer"
      newTaskTags="buy"
    />
    <h4>TaskItem</h4>
    <TaskItem
      :task="tasks[0]"
    />
    <h4>TaskList</h4>
    <TaskList
      :tasks="tasks"
    />
    <h4>ThemeSwitchButton</h4>
    <ThemeSwitchButton />
    <h4>ViewList</h4>
    <ViewList currentView="board" />
    <h4>HamburgerMenu</h4>
    <HamburgerMenu />
    <h4>TheNavBar</h4>
    <TheNavBar />
  </div>
</template>

<style scoped>
.designSystem {
  text-align: left;
}
h1, h2, h3, h4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
