import Tag from '../../models/tag'
import { toRaw } from 'vue'
import { TagService } from '@/services/tagService'
import { TaskService } from '@/services/taskService'
import { TaskRepository } from '../../repositories/TaskRepository'
import { TaskStatusRepository } from '../../repositories/TaskStatusRepository'
import { TaskTagRepository } from '../../repositories/TaskTagRepository'
import { TagRepository } from '../../repositories/TagRepository'
import { StatusService } from '../../services/statusService'
import { StatusRepository } from '../../repositories/StatusRepository'

const state = {
  all: [
  ]
}

const getters = {
  getTasks: state => {
    return state.all
  },
  getTask: (state) => (id) => {
    return state.all.find(task => task.id === id)
  },
  getTasksByConditions: (state, getters, rootState, rootGetters) => {
    const filter = rootState.route.query.filter
    const queryTag = rootState.route.query.tag
    const sortKey = rootState.route.query.sortKey || 'order'
    const sortOrder = rootState.route.query.sortOrder || 'asc'
    const sortParams = {
      key: sortKey,
      order: sortOrder
    }
    const query = rootState.route.query.query
    if (queryTag) {
      const tags = toRaw(rootGetters['tags/getTags'])
      const tagId = Tag.getTagId(queryTag, tags)
      const taskTags = rootGetters['taskTags/getTaskTags']
      const taskIds = taskTags.filter(x => x.tagId === tagId).map(x => x.taskId)
      return state.all.filter(x => { return taskIds.includes(x.id) }).sort((a, b) => { return (a.order < b.order) ? 1 : -1 }).reverse().sort(a => a.isStarred ? -1 : 1)
    }
    if (query) {
      return state.all.filter(x => { return x.name.includes(query) })
    }
    let filteredTasks
    switch (filter) {
      case 'starred':
        filteredTasks = state.all.filter(x => x.isStarred === true)
        break
      case 'unstarred':
        filteredTasks = state.all.filter(x => x.isStarred === false)
        break
      default:
        filteredTasks = state.all
        break
    }
    let sortedTasks
    switch (sortParams.key) {
      case 'created':
        sortedTasks = filteredTasks.sort((a, b) => { return (a.createdAt < b.createdAt) ? 1 : -1 })
        break
      case 'updated':
        sortedTasks = filteredTasks.sort((a, b) => { return (a.updatedAt < b.updatedAt) ? 1 : -1 })
        break
      case 'name':
        sortedTasks = filteredTasks.sort((a, b) => { return (a.name < b.name) ? 1 : -1 })
        break
      case 'order':
        sortedTasks = filteredTasks.sort((a, b) => { return (a.order < b.order) ? 1 : -1 })
        break
      default:
        sortedTasks = filteredTasks.sort((a, b) => { return (a.order < b.order) ? 1 : -1 })
        break
    }
    switch (sortParams.order) {
      case 'asc':
        sortedTasks = sortedTasks.reverse()
        break
      case 'desc':
        break
      default:
        break
    }
    sortedTasks = sortedTasks.sort(a => a.isStarred ? -1 : 1)
    return sortedTasks
  },
  getTasksWithStatusByTagId: (state, getters, rootState, rootGetters) => {
    const queryTag = rootState.route.query.tag
    const tags = toRaw(rootGetters['tags/getTags'])
    const tagId = Tag.getTagId(queryTag, tags)
    const taskTags = rootGetters['taskTags/getTaskTags']
    const taskIds = taskTags.filter(x => x.tagId === tagId).map(x => x.taskId)
    return state.all.filter(x => { return taskIds.includes(x.id) }).sort((a, b) => { return (a.order < b.order) ? 1 : -1 }).reverse().sort(a => a.isStarred ? -1 : 1)
  },
  getStatusEnabledTasks: state => {
    return state.all.filter(x => x.status !== undefined)
  },
  getTasksCountByStatusId: (state, getters) => statusId => {
    return getters.getStatusEnabledTasks.filter(x => x.status.id === statusId).length
  },
  getTasksByTagId: state => tagId => {
    return state.all.filter(x => x.tags.map(x => x.id).includes(tagId))
  },
  getTheOtherTags: (state, getters) => tagId => {
    return getters.getTasksByTagId(tagId).map(x => x.tags)
  },
  getUseStatusesOfTheOtherTags: (state, getters) => tagId => {
    return getters.getTheOtherTags(tagId).flat().filter(x => x.id !== tagId).map(x => x.useStatuses).includes(true)
  }
}

const mutations = {
  addTask (state, payload) {
    state.all.push(payload)
  },
  deleteTask (state, payload) {
    const index = state.all.map(x => x.id).indexOf(payload.id)
    state.all.splice(index, 1)
  },
  deleteTasks (state, payload) {
    for (const p of payload) {
      const index = state.all.map(x => x.id).indexOf(p)
      state.all.splice(index, 1)
    }
  },
  updateTask (state, payload) {
    const index = state.all.map(x => x.id).indexOf(payload.id)
    state.all[index] = payload
  },
  clearTasks (state) {
    state.all = []
  },
  load (state, payload) {
    state.all = payload
  }
}

const actions = {
  async addTask ({ dispatch, commit }, payload) {
    const service = new TaskService(
      new StatusRepository(),
      new TagRepository(),
      new TaskRepository(),
      new TaskStatusRepository(),
      new TaskTagRepository()
    )
    const result = await service.addTask(payload.taskName, payload.tagNames)
    commit('addTask', result)
    await dispatch('tags/load', null, { root: true })
    await dispatch('taskTags/load', null, { root: true })
    await dispatch('tasks/load', null, { root: true })
  },
  async deleteTask ({ dispatch, commit }, task) {
    const service = new TaskService(
      new StatusRepository(),
      new TagRepository(),
      new TaskRepository(),
      new TaskStatusRepository(),
      new TaskTagRepository()
    )
    await service.deleteTask(task.id)
    commit('deleteTask', task)
    await dispatch('tags/load', null, { root: true })
    await dispatch('taskTags/load', null, { root: true })
  },
  async deleteTasks ({ commit }, ids) {
    const service = new TaskService(
      new StatusRepository(),
      new TagRepository(),
      new TaskRepository(),
      new TaskStatusRepository(),
      new TaskTagRepository()
    )
    for (const id of ids) {
      await service.deleteTask(id)
    }
    commit('deleteTasks', ids)
  },
  async updateStatus ({ commit }, payload) {
    if (payload.statusId === null) {
      return
    }
    const service = new TaskService(
      new StatusRepository(),
      new TagRepository(),
      new TaskRepository(),
      new TaskStatusRepository(),
      new TaskTagRepository()
    )
    const taskObj = Object.assign({}, toRaw(payload.task))
    await service.updateStatus(payload.task, payload.statusId)
    const statusService = new StatusService(
      new StatusRepository()
    )
    const newStatus = await statusService.get(payload.statusId)
    const taskUpdated = taskObj
    taskUpdated.status = newStatus
    const currentTask = await service.get(taskUpdated.id)
    taskUpdated.isClosed = currentTask.isClosed
    commit('updateTask', taskUpdated)
  },
  async updateTaskName ({ commit }, payload) {
    const service = new TaskService(
      new StatusRepository(),
      new TagRepository(),
      new TaskRepository(),
      new TaskStatusRepository(),
      new TaskTagRepository()
    )
    const taskObj = Object.assign({}, toRaw(payload.task))
    delete taskObj.tags
    try {
      await service.updateTaskName(taskObj, payload.newName)
    } catch (error) {
      console.log(error)
    }
    payload.task.name = payload.newName
    commit('updateTask', payload.task)
  },
  async updateTaskOrder ({ commit }, payload) {
    const service = new TaskService(
      new StatusRepository(),
      new TagRepository(),
      new TaskRepository(),
      new TaskStatusRepository(),
      new TaskTagRepository()
    )
    const newOrder = await service.calcNewOrder(toRaw(payload.task), toRaw(payload.taskDropped))
    const taskObj = Object.assign({}, toRaw(payload.task))
    delete taskObj.tags
    await service.updateTaskOrder(taskObj, newOrder)
    payload.task.order = newOrder
    commit('updateTask', payload.task)
  },
  async updateTags ({ dispatch, commit }, payload) {
    const service = new TaskService(
      new StatusRepository(),
      new TagRepository(),
      new TaskRepository(),
      new TaskStatusRepository(),
      new TaskTagRepository()
    )
    const result = await service.updateTags(payload.task, payload.newTagNames)
    commit('updateTask', result)
    await dispatch('tags/load', null, { root: true })
    await dispatch('taskTags/load', null, { root: true })
  },
  async closeTask ({ commit }, task) {
    const service = new TaskService(
      new StatusRepository(),
      new TagRepository(),
      new TaskRepository(),
      new TaskStatusRepository(),
      new TaskTagRepository()
    )
    const taskObj = Object.assign({}, toRaw(task))
    delete taskObj.tags
    const result = await service.closeTask(taskObj)
    task.isClosed = result.isClosed
    task.status = result.status
    commit('updateTask', task)
  },
  async starTask ({ commit }, task) {
    const service = new TaskService(
      new StatusRepository(),
      new TagRepository(),
      new TaskRepository(),
      new TaskStatusRepository(),
      new TaskTagRepository()
    )
    const taskObj = Object.assign({}, toRaw(task))
    delete taskObj.tags
    await service.starTask(taskObj)
    task.isStarred = !task.isStarred
    commit('updateTask', task)
  },
  async clearTasks ({ dispatch, commit }) {
    await dispatch('tags/clearTags', null, { root: true })
    await dispatch('taskTags/clearTaskTags', null, { root: true })
    await dispatch('statuses/clearStatuses', null, { root: true })
    const service = new TaskService(
      new StatusRepository(),
      new TagRepository(),
      new TaskRepository(),
      new TaskStatusRepository(),
      new TaskTagRepository()
    )
    await service.clear()
    commit('clearTasks')
  },
  async addInitialCustomStatuses ({ commit }, payload) {
    const service = new TaskService(
      new StatusRepository(),
      new TagRepository(),
      new TaskRepository(),
      new TaskStatusRepository(),
      new TaskTagRepository()
    )
    await service.addInitialCustomStatuses(payload.tagId, payload.openStatusId, payload.closedStatusId)
  },
  async deleteCustomStatuses ({ commit }, tagId) {
    const service = new TaskService(
      new StatusRepository(),
      new TagRepository(),
      new TaskRepository(),
      new TaskStatusRepository(),
      new TaskTagRepository()
    )
    await service.deleteCustomStatuses(tagId)
  },
  async load ({ commit }) {
    const service = new TaskService(
      new StatusRepository(),
      new TagRepository(),
      new TaskRepository(),
      new TaskStatusRepository(),
      new TaskTagRepository()
    )
    const tagService = new TagService(
      new TagRepository(),
      new StatusRepository()
    )
    const result = await service.getAll()
    const allTags = await tagService.getAll()
    const taskTags = await service.getTaskTags()
    const statusService = new StatusService(new StatusRepository())
    const statuses = await statusService.getStatuses()
    const taskStatuses = await service.getTaskStatuses()
    for (const r of result) {
      const tagIds = taskTags.filter(x => x.taskId === r.id).map(x => x.tagId)
      const tags = allTags.filter(x => tagIds.includes(x.id))
      const taskStatus = taskStatuses.find(x => x.taskId === r.id)
      if (taskStatus !== undefined) {
        const status = statuses.find(x => x.id === taskStatus.statusId)
        r.status = status
      }
      r.tags = tags
    }
    commit('load', result)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
