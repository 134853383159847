import { TagService } from '@/services/tagService'
import { StatusRepository } from '../../repositories/StatusRepository'
import { TagRepository } from '../../repositories/TagRepository'
import { StatusService } from '../../services/statusService'

const state = {
  all: [
  ]
}

const getters = {
  getStatuses: state => {
    return state.all
  },
  getStatusesByTagId: state => tagId => {
    const statuses = state.all.filter(x => x.tagId === tagId)
    if (statuses.length !== 0) {
      const firstStatus = statuses.find(x => x.prevStatusId === null)
      const statusesSorted = []
      statusesSorted.push(firstStatus)
      let nextId = firstStatus.nextStatusId
      while (nextId !== null) {
        const nextStatus = statuses.find(x => x.id === nextId)
        statusesSorted.push(nextStatus)
        nextId = nextStatus.nextStatusId
      }
      return statusesSorted
    }
    return []
  }
}

const mutations = {
  addStatus (state, payload) {
    state.all.push(payload)
  },
  deleteStatus (state, payload) {
    const index = state.all.map(x => x.id).indexOf(payload.id)
    state.all.splice(index, 1)
  },
  load (state, payload) {
    state.all = payload
  },
  clearStatuses (state) {
    state.all = []
  }
}

const actions = {
  async addInitialCustomStatuses ({ commit }, tagId) {
    const tagService = new TagService(
      new TagRepository(),
      new StatusRepository()
    )
    const statuses = await tagService.addInitialStatuses(tagId)
    for (const s of statuses) {
      commit('addStatus', s.toJSON())
    }
    return statuses
  },
  async addStatus ({ dispatch, commit }, payload) {
    const statusService = new StatusService(
      new StatusRepository()
    )
    const status = await statusService.addStatus(payload.statusName, payload.tagId, payload.prevId, payload.nextId)
    commit('addStatus', status)
    const result = await statusService.getStatuses()
    commit('load', result)
    await dispatch('tasks/load', null, { root: true })
  },
  async clearStatuses ({ commit }) {
    const statusService = new StatusService(
      new StatusRepository()
    )
    await statusService.clear()
    commit('clearStatuses')
  },
  async deleteStatuses ({ commit }, tagId) {
    const tagService = new TagService(
      new TagRepository(),
      new StatusRepository()
    )
    const statusService = new StatusService(
      new StatusRepository()
    )
    const statuses = await tagService.deleteStatuses(tagId)
    for (const s of statuses) {
      commit('deleteStatus', s.id)
    }
    const result = await statusService.getStatuses()
    commit('load', result)
  },
  async deleteStatus ({ commit }, statusId) {
    const statusService = new StatusService(new StatusRepository())
    await statusService.deleteStatus(statusId)
    const result = await statusService.getStatuses()
    commit('load', result)
  },
  async load ({ commit }) {
    const statusService = new StatusService(
      new StatusRepository()
    )
    const result = await statusService.getStatuses()
    commit('load', result)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
