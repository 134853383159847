<script setup>
import { state } from '@/state'
</script>

<template>
  <div id="app">
    <TheNavBar />
    <AppModal :opened="dialogOpened">
      <AppInputText
        :maxlength="128"
        placeholder="Search"
        :value="modalSearchQuery"
        @emitKeyup="search"
        ref="modalNavInput"
      />
      <ul
        @click="toggleDialog"
        class="modal-ul my-0"
      >
        <li
          v-for="(item, index) in modalNavItemsAll.filter(x => x.name.includes(modalSearchQuery))"
          :key="item.id"
          :class="{ 'modal-nav-item-selected': index === modalNavIndexSelected }"
          class="px-4 h-8 hover"
        >
          <AppLink
            class="py-2 block px-2"
            :text=item.name
            :to="{ path: item.path, query: item.query }"
            :appearance="{ 'secondary': index !== modalNavIndexSelected, 'inverted': index === modalNavIndexSelected }"
          />
        </li>
      </ul>
      <p class="modal-description my-2">
        <span v-if="platform() === 'macOS'">"enter" to select, ↓↑ to navigate, cmd + k to close</span>
        <span v-else>"enter" to select, ↓↑ to navigate, control + k to close</span>
      </p>
    </AppModal>
    <main class="app-content">
      <router-view />
    </main>
    <footer class="text-secondary h-6">
      <small>
        <a href="https://sakih.net/" class="text-secondary">
          <span class="p-1">© 2021-2022 saki</span>
        </a>
      </small>
    </footer>
  </div>
</template>

<script>
import AppInputText from '@/components/AppInputText'
import AppLink from '@/components/AppLink'
import AppModal from '@/components/AppModal'
import TheNavBar from '@/components/TheNavBar'

export default {
  name: 'App',
  components: {
    AppInputText,
    AppLink,
    AppModal,
    TheNavBar
  },
  data () {
    return {
      dialogOpened: false,
      metaKeyPressed: false,
      kKeyPressed: false,
      modalSearchQuery: '',
      modalNavIndexSelected: 0,
      modalNavItems: [
        {
          name: 'home',
          path: '/',
          query: {}
        },
        {
          name: 'filter: starred',
          path: '/',
          query: {
            filter: 'starred'
          }
        },
        {
          name: 'filter: unstarred',
          path: '/',
          query: {
            filter: 'unstarred'
          }
        },
        {
          name: 'about',
          path: '/about',
          query: {}
        },
        {
          name: 'releases',
          path: '/releases',
          query: {}
        },
        {
          name: 'settings',
          path: '/settings',
          query: {}
        }
      ]
    }
  },
  computed: {
    tagLinks () {
      const tagLinks = []
      for (const tag of state.tags) {
        tagLinks.push({
          name: `tag: ${tag.name}`,
          path: '/',
          query: {
            tag: `${tag.name}`
          }
        })
      }
      return tagLinks
    },
    modalNavItemsAll () {
      return this.modalNavItems.concat(this.tagLinks)
    }
  },
  methods: {
    toggleDialog: function () {
      this.dialogOpened = !this.dialogOpened
      if (this.dialogOpened) {
        document.activeElement.blur()
        this.modalNavIndexSelected = 0
        this.modalSearchQuery = ''
        this.$nextTick(() => {
          const modalNavInput = this.$refs.modalNavInput
          modalNavInput.$el.focus()
        })
      } else {
        // FIXME: update focus
      }
    },
    search: function (e) {
      this.modalSearchQuery = e
      // FIXME: reset modalNavIndexSelected when key is not an arrow key
    },
    platform: function () {
      if (navigator.userAgentData) {
        return navigator.userAgentData.platform
      } else {
        const userAgent = navigator.userAgent
        if (userAgent.includes('Macintosh')) {
          return 'macOS'
        } else {
          return 'theOtherOS'
        }
      }
    }
  },
  created: function () {
    state.init()
    let modifierKey
    if (this.platform() === 'macOS') {
      modifierKey = 'Meta'
    } else {
      modifierKey = 'Control'
    }
    document.onkeydown = e => {
      if (this.dialogOpened) {
        if (e.key === 'ArrowDown') {
          if (this.modalNavIndexSelected <= this.modalNavItemsAll.length - 2) {
            this.modalNavIndexSelected = this.modalNavIndexSelected + 1
          } else {
            this.modalNavIndexSelected = 0
          }
        } else if (e.key === 'ArrowUp') {
          if (this.modalNavIndexSelected >= 1) {
            this.modalNavIndexSelected = this.modalNavIndexSelected - 1
          } else {
            this.modalNavIndexSelected = this.modalNavItemsAll.length - 1
          }
        } else if (e.key === 'Enter') {
          const selectedItemElem = document.querySelector('.modal-nav-item-selected')
          const href = selectedItemElem.children[0].getAttribute('href')
          if (href.includes('?')) {
            const [key, value] = href.split('/')[1].split('?')[1].split('=')
            const json = `{"${key}":"${value}"}`
            this.$router.push({ path: '/', query: JSON.parse(json) })
          } else {
            this.$router.push({ path: href })
          }
          this.toggleDialog()
        } else if (e.key === modifierKey) {
          this.metaKeyPressed = true
        } else if (this.metaKeyPressed && e.key === 'k') {
          this.kKeyPressed = true
        }
        if (this.kKeyPressed) {
          this.toggleDialog()
          this.metaKeyPressed = false
          this.kKeyPressed = false
        }
      } else {
        if (e.key === modifierKey) {
          this.metaKeyPressed = true
        } else if (this.metaKeyPressed && e.key === 'k') {
          this.kKeyPressed = true
        }
        if (this.kKeyPressed) {
          this.toggleDialog()
          this.metaKeyPressed = false
          this.kKeyPressed = false
        }
      }
    }
    document.onkeyup = e => {
      if (e.key === modifierKey) {
        this.metaKeyPressed = false
      } else if (e.key === 'k') {
        this.kKeyPressed = false
      }
    }
  }
}
</script>

<style>
/* reset */
* {
  box-sizing: border-box;
}
body,
h1,
h2,
h3,
h4,
p {
  margin: 0;
}
body {
  min-height: 100vh;
}
input,
button,
textarea,
select {
  font: inherit;
}
/* base */
html {
  overflow-y: scroll;
}
#app {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
.app-content {
  flex: 1;
}
.modal-ul {
  list-style: none;
  text-align: left;
  padding-left: 0;
}
.modal-nav-item-selected {
  background-color: var(--color-blue);
}
.modal-description {
  color: var(--color-text-secondary);
  text-align: left;
}
</style>
