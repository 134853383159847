<script setup>
import { computed } from 'vue'
import AppProgress from '@/components/AppProgress'
import { state } from '@/state'
state.init()

const tasksCount = computed(() => state.tasks.length)
const tasksCountClosed = computed(() => state.tasks.filter(x => x.isClosed).length)
</script>

<template>
  <div class="layout-center text-left text-secondary m-4">
    <h3 class="font-weight-normal text-large">Stats</h3>
    <div v-if="!state.isLoading">
      <div class="flex-row my-4">
        <p class="f-1">{{ tasksCountClosed }} / {{ tasksCount }}</p>
        <p class="f-1">{{ Math.round(tasksCountClosed / tasksCount * 100 ) || 0}}%</p>
      </div>
      <AppProgress
        :max="tasksCount"
        :value="tasksCountClosed"
      />
    </div>
  </div>
</template>

<style scoped>
</style>
