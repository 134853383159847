import { openDB } from 'idb'
import { name } from '../../package.json'

const VERSION = 3

async function getTasksStore () {
  const db = await openDB(name, VERSION)
  const store = db.transaction('tasks', 'readwrite').objectStore('tasks')
  return store
}

export class TaskRepository {
  async add (obj) {
    const store = await getTasksStore()
    const result = await store.add(obj)
    return result
  }

  async clear () {
    const store = await getTasksStore()
    const result = await store.clear()
    return result
  }

  async delete (id) {
    const store = await getTasksStore()
    const result = store.delete(id)
    return result
  }

  async get (id) {
    const store = await getTasksStore()
    const result = await store.get(id)
    return result
  }

  async getAll () {
    const store = await getTasksStore()
    const result = await store.getAll()
    return result
  }

  async put (obj) {
    const store = await getTasksStore()
    const result = store.put(obj)
    return result
  }
}
