export default class Tag {
  constructor (
    {
      id,
      name,
      createdAt = null,
      updatedAt = null,
      useStatuses = false,
      isPinned = false
    } = {}
  ) {
    if (id === undefined || name === undefined) {
      throw new Error()
    }
    this.id = id
    this.name = name
    const now = new Date().toISOString()
    this.createdAt = createdAt || now
    this.updatedAt = updatedAt || now
    this.useStatuses = useStatuses
    this.isPinned = isPinned
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      useStatuses: this.useStatuses,
      isPinned: this.isPinned
    }
  }

  updateName (name) {
    this.name = name
    this.updatedAt = new Date().toISOString()
  }

  toggleUseStatuses () {
    this.useStatuses = !this.useStatuses
    this.updatedAt = new Date().toISOString()
  }

  togglePinned () {
    this.isPinned = !this.isPinned
    this.updatedAt = new Date().toISOString()
  }

  static parseTagsString (string) {
    return string.trim().replace(/ /g, ',').split(',').filter(x => x)
  }

  static exists (name, tags) {
    return tags.map(x => x.name).includes(name)
  }

  static getTagId (name, tags) {
    if (tags.length !== 0) {
      return tags.filter(x => x.name === name)[0].id
    }
    return null
  }
}
