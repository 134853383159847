<script setup>
import { useStore } from 'vuex'
import AppButton from '@/components/AppButton'

const store = useStore()

const tagStrings = [
  'Alpha',
  'Blavo',
  'Charlie',
  'Delta',
  'Echo',
  'Foxtrot',
  'Golf',
  'Hotel',
  'India',
  'Juliet',
  'Kilo',
  'Lima',
  'Mike',
  'November',
  'Oscar',
  'Papa',
  'Quebec',
  'Romeo',
  'Sierra',
  'Tango',
  'Uniform',
  'Victor',
  'Whiskey',
  'X-ray',
  'Yankee',
  'Zulu'
]

const primeNumbers = [
  2, 3, 5, 7, 11, 13, 17, 19, 23, 29
]

const addTasks = async function () {
  for (let i = 0; i < 9; i++) {
    for (let j = 0; j < primeNumbers[i]; j++) {
      const newTaskName = 'task'
      const tagName = tagStrings[i]
      await store.dispatch('tasks/addTask', { taskName: newTaskName, tagNames: tagName })
    }
  }
}
</script>

<template>
  <div class="debug">
    <h1>Debug</h1>
    <AppButton
      text="Add data"
      @click="addTasks"
    />
  </div>
</template>

<style scoped>
.debug {
  text-align: left;
}
</style>
