<script setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import AppSelect from '@/components/AppSelect'
import FilterList from '@/components/FilterList'
import ViewList from '@/components/ViewList'

const route = useRoute()
const router = useRouter()
const props = defineProps({
  tags: {
    type: Array
  },
  selectedTagId: {
    type: String
  }
})
const selectedTag = computed(() => props.tags.find(x => x.id === props.selectedTagId))
const useStatusesOfSelectedTag = computed(() => {
  if (selectedTag.value) {
    return selectedTag.value.useStatuses
  } else {
    return false
  }
})
const updateTag = (event) => {
  const tag = props.tags.find(x => x.id === event)
  if (tag) {
    if (tag.useStatuses) {
      router.push({ path: '/', query: { tag: tag.name, view: route.query.view } })
    } else {
      router.push({ path: '/', query: { tag: tag.name } })
    }
  } else {
    router.push({ path: '/' })
  }
}
</script>

<template>
  <div class="layout-with-sidebar">
    <div class="layout-sidebar flex-column">
      <AppSelect
        class="m-auto"
        :options="props.tags"
        @changeValue="updateTag($event)"
        :canBeEmptied="true"
        :selected="props.selectedTagId"
      />
    </div>
    <div class="layout-not-sidebar flex-row justify-space-between">
      <div class="">
        <ViewList
          :currentTag="route.query.tag || ''"
          :currentView="route.query.view || ''"
          :useStatuses="useStatusesOfSelectedTag"
        />
      </div>
      <div class="">
        <FilterList
          :currentFilter="route.query.filter || ''"
          :currentTag="route.query.tag || ''"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
