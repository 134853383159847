export default class Status {
  constructor (
    {
      id,
      name,
      createdAt = null,
      updatedAt = null,
      tagId,
      prevStatusId = null,
      nextStatusId = null
    } = {}
  ) {
    if (id === undefined || name === undefined || tagId === undefined) {
      throw new Error()
    }
    this.id = id
    this.name = name
    const now = new Date().toISOString()
    this.createdAt = createdAt || now
    this.updatedAt = updatedAt || now
    this.tagId = tagId
    this.prevStatusId = prevStatusId
    this.nextStatusId = nextStatusId
  }

  toJSON () {
    return {
      id: this.id,
      name: this.name,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      tagId: this.tagId,
      prevStatusId: this.prevStatusId,
      nextStatusId: this.nextStatusId
    }
  }

  updateName (name) {
    this.name = name
    this.updatedAt = new Date().toISOString()
  }

  updateNextStatusId (statusId) {
    this.nextStatusId = statusId
    this.updateAt = new Date().toISOString()
  }

  updatePrevStatusId (statusId) {
    this.prevStatusId = statusId
    this.updateAt = new Date().toISOString()
  }
}
