<template>
  <div class="appTextLinkable">
    <span
      v-for="p in parsed"
      :key="p"
    >
      <span v-if="p.linkable">
        <a :href="p.text" target="_blank">{{ p.text }}</a>
      </span>
      <span v-else>{{ p.text }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'AppTextLinkable',
  props: {
    text: {
      type: String
    }
  },
  computed: {
    parsed () {
      const words = this.text.split(' ')
      const results = []
      for (const word of words) {
        try {
          const url = new URL(word)
          results.push({
            text: url.href,
            linkable: true
          })
        } catch (e) {
          results.push({
            text: word,
            linkable: false
          })
        }
        results.push(
          {
            text: ' ',
            linkable: false
          }
        )
      }
      return results
    }
  }
}
</script>

<style scoped>
.appTextLinkable {
}
</style>
