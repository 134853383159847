<script setup>
import { useStore } from 'vuex'
import CardItem from '@/components/CardItem'
import AppIconClear from '@/components/AppIconClear'

const store = useStore()
const props = defineProps({
  statuses: {
    type: Array
  },
  tasks: {
    type: Array
  }
})
const emit = defineEmits([
  'clickNext',
  'clickPrev',
  'clickStar',
  'enter',
  'reorder',
  'updateDraggedTask'
])
const deleteStatus = async (statusId) => {
  await store.dispatch('statuses/deleteStatus', statusId)
}
</script>

<template>
  <div class="f-1 flex-row overflow-x-scroll overflow-y-hidden ptn-scrollbar-thin">
    <div
      v-for="status in props.statuses"
      :key="status.id"
      class="cardsContainer flex-column mr-8 overflow-hidden"
    >
      <div class="h-8 flex-row mb-1">
        <div class="f-1">
          <h4 class="text-secondary font-weight-normal py-1">
            {{ status.name }}
          </h4>
        </div>
        <div class="w-8 text-secondary text-center py-1">
          {{ tasks.filter(x => x.status.id === status.id).length }}
        </div>
        <div class="w-8 text-secondary text-center py-1">
          <AppIconClear
            v-if="status.prevStatusId !== null && status.nextStatusId !== null  && tasks.filter(x => x.status.id === status.id).length === 0"
            @click="deleteStatus(status.id)"
            class="hover-text-blue"
          />
        </div>
      </div>
      <div class="f-1 layout-stack-2 flex-column overflow-y-auto ptn-scrollbar-thin pr-2 mb-4">
        <div
          v-for="task in props.tasks.filter(x => x.status.id === status.id)"
          :key="task.id"
        >
          <CardItem
            :task="task"
            @clickPrev="emit('clickPrev', $event)"
            @clickNext="emit('clickNext', $event)"
            @clickStar="emit('clickStar', $event)"
            @enter="emit('enter', $event)"
            @reorder="emit('reorder', $event)"
            @updateDraggedTask="emit('updateDraggedTask', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cardsContainer {
  flex-shrink: 0;
  flex-basis: 15rem;
}
</style>
