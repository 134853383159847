import { openDB } from 'idb'
import { name } from '../../package.json'

const VERSION = 3

async function getTaskTagsStore () {
  const db = await openDB(name, VERSION)
  const store = db.transaction('taskTags', 'readwrite').objectStore('taskTags')
  return store
}

export class TaskTagRepository {
  async getAll () {
    const store = await getTaskTagsStore()
    const result = await store.getAll()
    return result
  }

  async add (obj) {
    const store = await getTaskTagsStore()
    const result = await store.add(obj)
    return result
  }

  async delete (id) {
    const store = await getTaskTagsStore()
    const result = await store.delete(id)
    return result
  }
}
