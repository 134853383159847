<template>
  <transition name="modal">
    <div class="modal-mask" @click="clickModal">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <p>TaskModal</p>
            {{ task }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TaskModal',
  data () {
    return {
      task: {}
    }
  },
  computed: {
    ...mapGetters(
      'tasks',
      [
        'getTask'
      ]
    )
  },
  methods: {
    clickModal: function (e) {
      if (e.target.closest('.modal-container')) {
      } else {
        this.$router.push({ path: '/' })
      }
    }
  },
  created () {
    console.log('created')
  },
  updated () {
    console.log('updated')
  },
  mounted () {
    console.log('mounted')
    this.task = this.getTask(this.$route.params.id)
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  padding-top: 100px;
}
.modal-container {
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
