<script setup>
import { ref, reactive } from 'vue'
import AppIconPin from '@/components/AppIconPin'
import AppIconPinOutline from '@/components/AppIconPinOutline'
import AppLink from '@/components/AppLink'

defineProps({
  tag: {
    type: Object
  }
})
const emit = defineEmits([
  'clickPin',
  'clickPinOutline'
])
const isHovered = ref(false)
const obj = reactive({ isHovered })
const handleClickPin = (params) => {
  obj.isHovered = false
  emit('clickPin', params)
}
</script>

<template>
  <div
    class="f-1 hover overflow-hidden"
    @mouseover="obj.isHovered = true"
    @mouseleave="obj.isHovered = false"
  >
    <AppLink
      :to="{ path: '/', query: { tag: tag.name } }"
      appearance="secondary"
      class=""
    >
      <div class="flex-row pr-2">
        <div class="f-1 flex-column overflow-x-hidden text-overflow-ellipsis">
          <span class="m-2">{{ tag.name }}</span>
        </div>
        <div class="w-6 text-secondary text-right flex-column">
          <span class="my-2">{{ tag.count }}</span>
        </div>
        <div class="w-6 text-secondary text-right flex-column">
          <AppIconPin v-if="tag.isPinned"
            class="m-2 hover-text-blue"
            @click="handleClickPin({ event: $event, id: tag.id })"
          />
          <AppIconPinOutline
            v-else-if="!tag.isPinned && isHovered"
            class="m-2 hover-text-blue"
            @click="emit('clickPinOutline', { event: $event, id: tag.id })"
          />
        </div>
      </div>
    </AppLink>
  </div>
</template>

<style scoped>
</style>
