export default class TaskStatus {
  constructor (
    {
      id,
      taskId,
      statusId
    } = {}
  ) {
    this.id = id
    this.taskId = taskId
    this.statusId = statusId
  }

  updateStatusId (statusId) {
    this.statusId = statusId
  }

  toJSON () {
    return {
      id: this.id,
      taskId: this.taskId,
      statusId: this.statusId
    }
  }
}
