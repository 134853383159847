import { v4 as uuidv4 } from 'uuid'
import Status from '@/models/status'

export class StatusService {
  constructor (
    statusRepository
  ) {
    this.statusRepository = statusRepository
  }

  async addInitialCustomStatuses (tagId) {
    const openStatusId = uuidv4()
    const closedStatusId = uuidv4()
    const open = new Status(
      {
        id: openStatusId,
        name: 'open',
        createdAt: null,
        updatedAt: null,
        tagId: tagId,
        prevStatusId: null,
        nextStatusId: closedStatusId
      }
    )
    const closed = new Status(
      {
        id: closedStatusId,
        name: 'closed',
        createdAt: null,
        updatedAt: null,
        tagId: tagId,
        prevStatusId: openStatusId,
        nextStatusId: null
      }
    )
    const openStatus = await this.statusRepository.add(open.toJSON())
    const closedStatus = await this.statusRepository.add(closed.toJSON())
    return [openStatus, closedStatus]
  }

  async addStatus (statusName, tagId, prevId, nextId) {
    const s = new Status(
      {
        id: uuidv4(),
        name: statusName,
        createdAt: null,
        updatedAt: null,
        tagId: tagId,
        prevStatusId: prevId,
        nextStatusId: nextId
      }
    )
    const prevStatusObj = await this.statusRepository.get(prevId)
    const nextStatusObj = await this.statusRepository.get(nextId)
    const prevStatus = new Status(prevStatusObj)
    const nextStatus = new Status(nextStatusObj)
    prevStatus.updateNextStatusId(s.id)
    nextStatus.updatePrevStatusId(s.id)
    await this.statusRepository.add(s.toJSON())
    await this.statusRepository.put(prevStatus.toJSON())
    await this.statusRepository.put(nextStatus.toJSON())
    return s.toJSON()
  }

  async clear () {
    const result = await this.statusRepository.clear()
    return result
  }

  async deleteStatus (id) {
    const targetStatusObj = await this.statusRepository.get(id)
    const targetStatus = new Status(targetStatusObj)
    if (targetStatus.prevStatusId !== null && targetStatus.nextStatusId !== null) {
      const prevStatus = new Status(await this.statusRepository.get(targetStatus.prevStatusId))
      const nextStatus = new Status(await this.statusRepository.get(targetStatus.nextStatusId))
      prevStatus.updateNextStatusId(targetStatus.nextStatusId)
      nextStatus.updatePrevStatusId(targetStatus.prevStatusId)
      await this.statusRepository.put(prevStatus.toJSON())
      await this.statusRepository.put(nextStatus.toJSON())
    }
    await this.statusRepository.delete(targetStatus.id)
    return targetStatus.toJSON()
  }

  async get (id) {
    const status = await this.statusRepository.get(id)
    return status
  }

  async getStatuses () {
    const statuses = await this.statusRepository.getAll()
    return statuses
  }

  async getStatusesByTagId (tagId) {
    const statuses = await this.statusRepository.getAll()
    const targetStatusIds = statuses.filter(x => x.tagId === tagId).map(x => x.id)
    return targetStatusIds
  }
}
