<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { state } from '@/state'
import AppIconClear from '@/components/AppIconClear'
import AppInputSwitch from '@/components/AppInputSwitch'
import AppLink from '@/components/AppLink'
import TagItem from '@/components/TagItem'

const route = useRoute()
const store = useStore()
const props = defineProps({
  tags: {
    type: Array
  },
  currentTag: {
    type: String
  }
})
const selectedTag = computed(() => props.tags.find(x => x.name === route.query.tag))
const statuses = computed(() => store.getters['statuses/getStatusesByTagId'](selectedTag.value.id))
const tasksCountByStatusId = (statusId) => computed(() => store.getters['tasks/getTasksCountByStatusId'](statusId))
const addStatus = async (event, tagId, prevStatusId, nextStatusId) => {
  const payload = {
    statusName: event.target.value,
    tagId: tagId,
    prevId: prevStatusId,
    nextId: nextStatusId
  }
  await store.dispatch('statuses/addStatus', payload)
  event.target.value = ''
}
const deleteStatus = async (statusId) => {
  await store.dispatch('statuses/deleteStatus', statusId)
}
const togglePinned = async (params) => {
  params.event.preventDefault()
  await store.dispatch('tags/togglePinned', params.id)
  await store.dispatch('tags/load')
}
const toggleUseStatuses = async (tagId) => {
  await store.dispatch('tags/toggleUseStatuses', tagId)
  if (selectedTag.value.useStatuses) {
    const statuses = await store.dispatch('statuses/addInitialCustomStatuses', selectedTag.value.id)
    await store.dispatch(
      'tasks/addInitialCustomStatuses',
      {
        tagId: selectedTag.value.id,
        openStatusId: statuses[0].toJSON().id,
        closedStatusId: statuses[1].toJSON().id
      }
    )
  } else {
    await store.dispatch('statuses/deleteStatuses', selectedTag.value.id)
    await store.dispatch('tasks/deleteCustomStatuses', selectedTag.value.id)
  }
  await store.dispatch('tasks/load')
  await state.load() // TODO
}
</script>

<template>
  <div class="text-secondary text-left flex-column w-full">
    <div class="f-1">
      <h3 class="font-weight-normal text-large">Tags</h3>
      <div class="flex-row h-6">
        <div class="f-1"></div>
        <div>
          <AppLink
            v-if="currentTag"
            :to="{ path: '/' }"
            class="text-small"
          >Clear</AppLink>
        </div>
      </div>
      <ul class="pl-0 my-2">
        <li
          v-for="tag in props.tags"
          :key="tag.id"
          class="tagListLi h-8 flex-row"
          :class="{ exact: (props.currentTag === tag.name) ? true : false }"
        >
          <TagItem
            :tag="tag"
            @clickPin="togglePinned($event)"
            @clickPinOutline="togglePinned($event)"
          />
        </li>
      </ul>
    </div>
    <div class="f-1">
      <div v-if="currentTag !== ''" class="my-2">
        <div>
          <h4 class="font-weight-normal">Statuses</h4>
          <div class="h-8 flex-column">
            <div class="my-auto">
              <AppInputSwitch
                :isChecked="selectedTag.useStatuses"
                @change="toggleUseStatuses(selectedTag.id)"
              />
            </div>
          </div>
          <ul class="list-style-none pl-0 my-0 mx-2" v-if="selectedTag.useStatuses">
            <li
              v-for="status in statuses"
              :key="status.id"
            >
              <div class="flex-row h-6 my-1">
                <div class="f-1">
                  <span class="">{{ status.name }}</span>
                </div>
                <div class="w-12 flex-row text-right">
                  <div class="w-6 h-6 text-center">
                    <span>{{ tasksCountByStatusId(status.id) }}</span>
                  </div>
                  <div class="w-6 h-6 flex-column text-center">
                    <AppIconClear
                      v-if="status.prevStatusId !== null && status.nextStatusId !== null && tasksCountByStatusId(status.id).value === 0"
                      @click="deleteStatus(status.id)"
                      class="hover-text-blue"
                    />
                  </div>
                </div>
              </div>
              <div class="h-6 my-1">
                <div class="my-auto">
                  <input
                    class="w-full border-1"
                    type="text"
                    v-if="status.nextStatusId !== null"
                    @keyup.enter="addStatus($event, status.tagId, status.id, status.nextStatusId)"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.tagListLi {
  border-left: 2px solid var(--color-border-default);
}
.exact {
  border-left: 2px solid var(--color-blue);
}
input {
  border: 1px solid var(--color-border-default);
  background-color: var(--color-input-background);
  color: var(--color-text-primary);
}
input:focus {
  outline: var(--color-blue) 1px solid;
}
</style>
