<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import AppInputSwitch from '@/components/AppInputSwitch.vue'

const store = useStore()
const route = useRoute()
store.dispatch('tags/load')
const selectedTag = computed(() => store.state.tags.all.find(x => x.id === route.params.id))
store.dispatch('statuses/load')
const statuses = computed(() => store.getters['statuses/getStatusesByTagId'](selectedTag.value.id))
</script>

<template>
  <div class="layout-center text-left text-secondary m-4">
    <div>
      <h3 class="font-weight-normal text-large">Tag</h3>
    </div>
    <div class="h-8 flex-column">
      <span class="my-auto">tag: {{ selectedTag.name }}</span>
    </div>
    <div>
      <h4 class="font-weight-normal">Statuses</h4>
      <div class="h-8 flex-column">
        <AppInputSwitch
          class="my-auto"
          :isChecked="selectedTag.useStatuses"
        />
      </div>
      <ul class="pl-0 my-0 list-style-none">
        <li
          class="h-8 flex-column pl-2"
          v-for="status in statuses"
          :key="status.id"
        >
          <span class="my-auto">{{ status.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
</style>
