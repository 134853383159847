<script setup>
import { computed } from 'vue'
import { name } from '../../package.json'

const capitalize = computed(() => name[0].toUpperCase() + name.slice(1))
</script>

<template>
  <div class="about flex-column">
    <div class="layout-center text-left">
      <div class="m-2">
        <h1 class="text-xlarge mb-6">About</h1>
        <p class="text-medium">{{ capitalize }} is a progressive task management app for individuals.</p>
        <p class="text-medium">The focus of this app is making your task management more speedy, secure and serene.</p>
        <p class="text-medium">Your task data is only stored on your browser's storage, so there is no risk of information leakage.</p>
        <p class="text-medium">This app uses Google Analytics.</p>
        <h2 class="text-large my-5">Features</h2>
        <ul class="text-medium">
          <li>Star
            <ul>
              <li>Star your tasks to display it at the top of the list.</li>
            </ul>
          </li>
          <li>Manual Sorting
            <ul>
              <li>Sort your tasks in any order you want.</li>
            </ul>
          </li>
          <li>Tags
            <ul>
              <li>Add any tag to manage your tasks in multiple list views.</li>
            </ul>
          </li>
          <li>Statuses
            <ul>
              <li>Once you have enabled status with tags, you can define status sets for tagged tasks and use them for detailed task management.</li>
            </ul>
          </li>
          <li>Command menu
            <ul>
              <li>Planning to use keyboard shortcuts for fast screen transitions and task management.</li>
            </ul>
          </li>
        </ul>
        <h2 class="text-large my-5">Business Model</h2>
        <p class="text-medium">Not now. I'm running this site to make what I want and to learn about various things. </p>
        <h2 class="text-large my-5">Technology</h2>
        <ul class="text-medium">
          <li>PWA</li>
          <li>JavaScript</li>
          <li>Vue 3</li>
          <li>IndexedDB</li>
        </ul>
        <p class="text-medium">There is absolutely nothing interesting about the {{ capitalize }} architecture or implementation; I consider that a feature.</p>
        <h2 class="text-large my-5">Feedback</h2>
        <p class="text-medium">Feature requests and general discussion are welcome at the <a href="https://github.com/sakihet/rydeen-discussions/discussions">GitHub Discussions.</a></p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.about {
}
</style>
