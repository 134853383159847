import { openDB } from 'idb'
import { name } from '../package.json'

const VERSION = 3

async function getTaskTagsStore () {
  const db = await openDB(name, VERSION)
  const store = db.transaction('taskTags', 'readwrite').objectStore('taskTags')
  return store
}

export async function connectDB () {
  await openDB(name, VERSION, {
    upgrade (db, oldVersion, newVersion, transaction) {
      switch (oldVersion) {
        case 0:
          db.createObjectStore('tasks', { keyPath: 'id' })
          db.createObjectStore('tags', { keyPath: 'id' })
          db.createObjectStore('taskTags', { keyPath: 'id' })
          db.createObjectStore('statuses', { keyPath: 'id' })
          db.createObjectStore('taskStatuses', { keyPath: 'id' })
          break
        case 1:
          db.createObjectStore('tags', { keyPath: 'id' })
          db.createObjectStore('taskTags', { keyPath: 'id' })
          break
        case 2:
          db.createObjectStore('statuses', { keyPath: 'id' })
          db.createObjectStore('taskStatuses', { keyPath: 'id' })
          break
        default:
          console.error('unknown db version')
      }
    }
  })
}

export async function getAllTaskTags () {
  const store = await getTaskTagsStore()
  const result = await store.getAll()
  return result
}

export async function addTaskTag (obj) {
  const store = await getTaskTagsStore()
  const result = await store.add(obj)
  return result
}

export async function deleteTaskTag (id) {
  const store = await getTaskTagsStore()
  const result = await store.delete(id)
  return result
}

export async function clearTaskTags () {
  const store = await getTaskTagsStore()
  const result = await store.clear()
  return result
}
