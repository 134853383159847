<script setup>
import { reactive } from 'vue'
import { version } from '../../package.json'
import AppLink from '@/components/AppLink'
import AppLinkLogo from '@/components/AppLinkLogo'
import HamburgerMenu from '@/components/HamburgerMenu'
import ThemeSwitchButton from '@/components/ThemeSwitchButton'

const state = reactive({
  open: false
})
const toggleHamburgerMenu = (event) => {
  if (event.target) {
    state.open = event.target.open
  } else {
    state.open = false
  }
}
const handleClick = (event) => {
  toggleHamburgerMenu(event)
}
</script>

<template>
  <div class="flex-row shadow-1 h-12 px-4">
    <div class="f-5 text-left m-auto">
      <span class="text-secondary">v{{ version }}</span>
    </div>
    <div class="f-2 m-auto nowrap text-center">
      <img
        src="../assets/logo.png"
        width="24"
        height="24"
        class="vertical-align-middle"
      >
      <AppLinkLogo
        text="Rydeen"
        to="/"
        class="vertical-align-middle"
      />
    </div>
    <div class="nav-bar-right f-5 text-right m-auto">
      <div class="nav-menu-wide">
        <AppLink
          to="/about"
          appearance="secondary"
          class="inline-block hover"
        >
          <div class="h-12 py-4 px-2">About</div>
        </AppLink>
        <AppLink
          to="/releases"
          appearance="secondary"
          class="inline-block hover"
        >
          <div class="h-12 py-4 px-2">Releases</div>
        </AppLink>
        <AppLink
          to="/settings"
          appearance="secondary"
          class="inline-block hover"
        >
          <div class="h-12 py-4 px-2">Settings</div>
        </AppLink>
        <ThemeSwitchButton />
      </div>
      <div class="nav-menu-narrow my-2">
        <HamburgerMenu
          :open="state.open"
          @emitToggle="toggleHamburgerMenu($event)"
        >
          <ul
            class="list-style-none bg-color-secondary shadow-1 p-2"
            style="position: relative; top: 0.5rem; z-index: 1"
          >
            <li class="h-8 hover px-2">
              <AppLink
                to="/stats"
                appearance="secondary"
                @click="handleClick($event)"
              >
                <div class="py-2">
                  <span>Stats</span>
                </div>
              </AppLink>
            </li>
            <li class="h-8 hover px-2">
              <AppLink
                to="/about"
                appearance="secondary"
                @click="handleClick($event)"
              >
                <div class="py-2">
                  <span>About</span>
                </div>
              </AppLink>
            </li>
            <li class="h-8 hover px-2">
              <AppLink
                to="/releases"
                appearance="secondary"
                @click="handleClick($event)"
              >
                <div class="py-2">
                  <span>Releases</span>
                </div>
              </AppLink>
            </li>
            <li class="h-8 hover px-2">
              <AppLink
                to="/settings"
                appearance="secondary"
                @click="handleClick($event)"
              >
                <div class="py-2">
                  <span>Settings</span>
                </div>
              </AppLink>
            </li>
          </ul>
        </HamburgerMenu>
      </div>
    </div>
  </div>
</template>

<style scoped>
.nav-bar-right > div > .router-link-exact-active > div {
  border-bottom: 2px solid var(--color-blue);
}
.nav-menu-wide {
  display: block;
}
.nav-menu-narrow {
  display: none;
}
@media (max-width: 50rem) {
  .nav-menu-wide {
    display: none;
  }
  .nav-menu-narrow {
    display: block;
  }
}
</style>
