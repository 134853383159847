<script setup>
import AppButton from '@/components/AppButton'
import AppIconStar from '@/components/AppIconStar'
import AppIconStarOutline from '@/components/AppIconStarOutline'
// import AppTextIcon from '@/components/AppTextIcon'

defineProps({
  task: {
    type: Object
  }
})
const emit = defineEmits([
  'clickNext',
  'clickPrev',
  'clickStar',
  'enter',
  'reorder',
  'updateDraggedTask'
])
const dragend = (e) => {
  e.target.style.opacity = ''
}
const dragover = (e) => {
  e.preventDefault()
}
const dragstart = (e, task) => {
  e.target.style.opacity = 0.5
  emit('updateDraggedTask', task)
}
const drop = (task) => {
  emit('reorder', task)
}
</script>

<template>
  <div
    class="bg-color-secondary border-1 flex-column max-w-60 py-2 pr-2 hover"
    :draggable="false"
    @dragend="dragend($event)"
    @dragover="dragover($event)"
    @dragstart="dragstart($event, task)"
    @drop="drop(task)"
  >
    <div class="f-1 flex-row">
      <div class="w-4 h-4 pr-2 flex-column">
        <!-- <AppTextIcon
          v-if="task.isStarred"
          class="m-auto"
          text="⠿"
          cursor="not-allowed"
        />
        <AppTextIcon
          v-else
          class="m-auto"
          text="⠿"
          cursor="grab"
        /> -->
      </div>
      <div
        class="f-1 overflow-hidden"
        contenteditable="true"
        v-bind:class="{ closed: task.isClosed }"
        @keydown.enter.prevent="emit('enter', { task, $event })"
      >
        {{ task.name }}
      </div>
      <div class="w-4 text-center">
        <AppIconStar
          v-if="task.isStarred"
          class="m-auto hover-text-blue"
          @click="$emit('clickStar', task)"
        />
        <AppIconStarOutline
          v-else
          class="m-auto hover-text-blue"
          @click="$emit('clickStar', task)"
        />
      </div>
    </div>
    <div class="h-6 flex-row">
      <div class="f-1">
      </div>
      <div class="f-1 text-center flex-row">
        <AppButton
          class="f-1 hover-text-blue"
          text="<"
          appearance="textOnly"
          @click="emit('clickPrev', task)"
        />
        <AppButton
          class="f-1 hover-text-blue"
          text=">"
          appearance="textOnly"
          @click="emit('clickNext', task)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.closed {
  text-decoration: line-through;
  color: var(--color-text-secondary)
}
</style>
