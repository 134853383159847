<script setup>
// import { store } from '../storeExperimental.js'
// store.load()

import { computed } from 'vue'
import { useStore } from 'vuex'
import AppLink from '@/components/AppLink'

const store = useStore()
const tags = computed(() => store.state.tags.all)
store.dispatch('tags/load')
</script>

<template>
  <div class="layout-center text-left text-secondary m-4">
    <h3 class="font-weight-normal text-large">Tags</h3>
    <ul class="list-style-none pl-0 my-0">
      <li
        class="h-8 hover"
        v-for="tag in tags"
        :key="tag.id"
      >
        <AppLink
          :to="`/tags/${tag.id}`"
        >
          <div class="h-8 flex-column">
            <span class="my-auto px-2">{{ tag.name }}</span>
          </div>
        </AppLink>
      </li>
    </ul>
  </div>
</template>

<style scoped>
</style>
