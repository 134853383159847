import { createStore } from 'vuex'
import statuses from './modules/statuses'
import tags from './modules/tags'
import tasks from './modules/tasks'
import taskTags from './modules/taskTags'
import { connectDB } from '@/db'

export default createStore({
  state: {
  },
  mutations: {
    connectDB () {
      console.log('connectDB')
    }
  },
  actions: {
    async connectDB ({ commit }) {
      await connectDB()
      commit('connectDB')
    }
  },
  modules: {
    statuses,
    tags,
    tasks,
    taskTags
  }
})
