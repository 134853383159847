<script setup>
import { ref, reactive } from 'vue'
import AppCheckbox from '@/components/AppCheckbox'
import AppIconClear from '@/components/AppIconClear'
import AppIconDragIndicator from '@/components/AppIconDragIndicator'
import AppIconStar from '@/components/AppIconStar'
import AppIconStarOutline from '@/components/AppIconStarOutline'

defineProps({
  task: {
    type: Object
  }
})
const emit = defineEmits([
  'clickCheckbox',
  'clickDelete',
  'clickStar',
  'enter',
  'enterTags',
  'reorder',
  'updateDraggedTask'
])
const isHovered = ref(false)
const obj = reactive({ isHovered })
const dragend = (e) => {
  e.target.style.opacity = ''
}
const dragenter = (e, task) => {
  if (e.target.className === 'taskItem' && e.relatedTarget.className === 'taskItem') {
    if (e.target.style) {
      // e.target.style.backgroundColor = '#eeeeee'
      // e.target.style.opacity = 0.5
    }
  }
}
const dragleave = (e) => {
  if (e.target.className === 'taskItem' && e.relatedTarget.className === 'taskItem') {
    if (e.target.nodeType === 3) return
    if (e.relatedTarget) {
      if (e.relatedTarget.nodeType === 3) return
    }
    if (e.target.style) {
      // e.target.style.backgroundColor = ''
      // e.target.style.opacity = ''
    }
  }
}
const dragover = (e) => {
  e.preventDefault()
}
const dragstart = (e, task) => {
  e.target.style.opacity = 0.5
  emit('updateDraggedTask', task)
}
const drop = (e, task) => {
  emit('reorder', task)
}
const handleMouseOver = () => {
  obj.isHovered = true
}
const handleMouseLeave = () => {
  obj.isHovered = false
}
</script>

<template>
  <div
    class="h-8 flex-row text-primary border-1 overflow-hidden pr-1 bg-color-secondary hover"
    :draggable="!task.isStarred"
    @dragend="dragend($event)"
    @dragenter="dragenter($event, task)"
    @dragleave="dragleave($event, task)"
    @dragover="dragover($event)"
    @dragstart="dragstart($event, task)"
    @drop="drop($event, task)"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <div class="w-4 h-8 flex-column">
      <AppIconDragIndicator
        v-if="task.isStarred"
        class="m-auto text-disabled"
        cursor="not-allowed"
        size="small"
      />
      <AppIconDragIndicator
        v-else
        class="m-auto text-secondary"
        cursor="grab"
        size="small"
      />
    </div>
    <div class="w-8 h-8 flex-column">
      <AppCheckbox
        class="m-auto"
        :isChecked="task.isClosed"
        @click="emit('clickCheckbox', task)"
      />
    </div>
    <div class="f-9 overflow-hidden">
      <div
        class="my-2 nowrap overflow-hidden text-overflow-ellipsis"
        v-bind:class="{ 'text-line-through text-secondary': task.isClosed }"
        contenteditable="true"
        @keydown.enter.prevent="emit('enter', { task, $event })"
      >
        {{ task.name }}
      </div>
    </div>
    <div class="f-3 overflow-hidden">
      <div
        class="my-2 text-secondary nowrap overflow-hidden text-overflow-ellipsis"
        v-if="task.tags"
        contenteditable="true"
        @keydown.enter.prevent="emit('enterTags', { task, $event })"
      >
        {{ (task.tags.length !== 0 ? task.tags.map(x => x.name).join(',') : '')}}
      </div>
    </div>
    <div class="flex-row">
      <div class="w-6 h-8 text-center flex-column">
        <div class="m-auto">
          <AppIconStar
            v-if="task.isStarred"
            @click="emit('clickStar', task)"
            class="hover-text-blue"
          />
          <AppIconStarOutline
            v-else
            @click="emit('clickStar', task)"
            class="hover-text-blue"
          />
        </div>
      </div>
      <div class="w-6 h-8 text-center flex-column">
        <div class="m-auto">
          <AppIconClear
            @click="emit('clickDelete', task)"
            class="hover-text-blue"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
</script>

<style scoped>
</style>
