import { addTaskTag, getAllTaskTags, deleteTaskTag, clearTaskTags } from '@/db'

const state = {
  all: [
  ]
}

const getters = {
  getTaskTags: state => {
    return state.all
  }
}

const mutations = {
  addTaskTag (state, payload) {
    state.all.push(payload)
  },
  deleteTaskTag (state, payload) {
    const index = state.all.map(x => x.id).indexOf(payload.id)
    state.all.splice(index, 1)
  },
  clearTaskTags (state) {
    state.all = []
  },
  load (state, payload) {
    state.all = payload
  }
}

const actions = {
  async addTaskTag ({ commit }, taskTag) {
    await addTaskTag(taskTag)
    commit('addTaskTag', taskTag)
  },
  async deleteTaskTag ({ commit }, taskTag) {
    await deleteTaskTag(taskTag.id)
    commit('deleteTaskTag', taskTag)
  },
  async clearTaskTags ({ commit }) {
    await clearTaskTags()
    commit('clearTaskTags')
  },
  async load ({ commit }) {
    const result = await getAllTaskTags()
    commit('load', result)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
