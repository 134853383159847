<script setup>
</script>

<template>
  <div class="text-left">
    <div class="layout-center text-medium">
      <div class="m-2">
        <h1 class="text-xlarge mb-6">Releases</h1>
        <p class="text-medium">Minor fixes will always be made in the patch version.</p>
        <h2 class="text-large my-5">Past</h2>
        <h3>0.11.0 <small>2022/07/31</small></h3>
        <ul>
          <li>Add dark mode</li>
        </ul>
        <h3>0.10.0 <small>2022/06/23</small></h3>
        <ul>
          <li>Add pin feature</li>
        </ul>
        <h3>0.9.0 <small>2022/05/27</small></h3>
        <ul>
          <li>Redesign</li>
        </ul>
        <h3>0.8.0 <small>2021/11/18</small></h3>
        <ul>
          <li>Add statuses feature</li>
        </ul>
        <h3>0.7.0 <small>2021/09/20</small></h3>
        <ul>
          <li>Add command menu UI</li>
        </ul>
        <h3>0.6.0 <small>2021/03/16</small></h3>
        <ul>
          <li>Add manual sorting feature</li>
        </ul>
        <h3>0.5.0 <small>2021/02/25</small></h3>
        <ul>
          <li>Add tag updating</li>
          <li>Update the architecture</li>
        </ul>
        <h3>0.4.0 <small>2021/02/14</small></h3>
        <ul>
          <li>Add tag feature</li>
        </ul>
        <h3>0.3.0 <small>2021/02/11</small></h3>
        <ul>
          <li>Add search feature</li>
        </ul>
        <h3>0.2.0 <small>2021/01/28</small></h3>
        <ul>
          <li>Add star featufe</li>
        </ul>
        <h3>0.1.0 <small>2021/01/26</small></h3>
        <ul>
          <li>First release</li>
        </ul>
        <h2 class="text-large my-5">Future plans</h2>
        <ul>
          <li>Bulk editing</li>
          <li>Coloring</li>
          <li>Import/Export</li>
          <li>Keyboard first design</li>
          <li>Note management</li>
          <li>Support mobile</li>
          <li>Tag management</li>
          <li>Task Details</li>
          <li>Timeline view</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
