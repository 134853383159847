import { createRouter, createWebHistory } from 'vue-router'
import About from '../views/About.vue'
import Home from '../views/Home.vue'
import Releases from '../views/Releases.vue'
import Settings from '../views/Settings.vue'
import TagPage from '../views/TagPage.vue'
import TagsPage from '../views/TagsPage.vue'
import Debug from '../views/Debug.vue'
import DesignSystem from '../views/DesignSystem.vue'
import Sandbox from '../views/Sandbox.vue'
import StatsPage from '../views/StatsPage.vue'
import TaskModal from '../components/TaskModal.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: to => {
      const redirect = to.query.redirect
      if (redirect) {
        return {
          path: `/${redirect}`
        }
      }
    }
  },
  {
    path: '/tasks',
    component: Home,
    children: [
      {
        path: ':id',
        component: TaskModal,
        name: 'TaskModal'
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/releases',
    name: 'Releases',
    component: Releases
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/stats',
    name: 'StatsPage',
    component: StatsPage
  },
  {
    path: '/tags',
    name: 'TagsPage',
    component: TagsPage
  },
  {
    path: '/tags/:id',
    name: 'TagPage',
    component: TagPage
  },
  {
    path: '/debug',
    name: 'Debug',
    component: Debug
  },
  {
    path: '/design-system',
    name: 'DesignSystem',
    component: DesignSystem
  },
  {
    path: '/sandbox',
    name: 'Sandbox',
    component: Sandbox
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
