<template>
  <span class='app-datetime'>
    {{ dayjsFormat(datetime) }}
  </span>
</template>

<script>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'now',
    m: 'a min ago',
    mm: '%d min ago',
    h: 'an hour ago',
    hh: '%d hours ago',
    d: 'a day ago',
    dd: '%d days ago',
    M: 'a month ago',
    MM: '%d months ago',
    y: 'a year ago',
    yy: '%d years ago'
  }
})

export default {
  name: 'AppDatetime',
  props: {
    datetime: {
      type: String
    }
  },
  methods: {
    dayjsFormat: function (date) {
      return dayjs().to(dayjs(date), true)
    }
  }
}
</script>

<style scoped>
.app-datetime {
  color: var(--color-text-secondary);
  font-size: 0.8em;
}
</style>
