import { computed, reactive } from 'vue'
import { TaskService } from '@/services/taskService'
import { TagService } from '@/services/tagService'
import { TaskRepository } from '@/repositories/TaskRepository'
import { TaskStatusRepository } from '@/repositories/TaskStatusRepository'
import { TaskTagRepository } from '@/repositories/TaskTagRepository'
import { TagRepository } from '@/repositories/TagRepository'
import { StatusRepository } from '@/repositories/StatusRepository'

const storageKey = 'theme'
const getTheme = () => {
  if (localStorage.getItem(storageKey)) {
    return localStorage.getItem(storageKey)
  } else {
    return window.matchMedia('(preferes-color-scheme: dark)').matches ? 'dark' : 'light'
  }
}
const applyTheme = (theme) => {
  if (theme === 'dark') {
    document.firstElementChild.classList.add('dark')
  } else {
    document.firstElementChild.classList.remove('dark')
  }
}

export const state = reactive({
  isLoading: true,
  theme: 'light',
  tasks: [],
  tags: [],
  tagsSorted: computed(() => state.tags.sort((a, b) => { return (a.name > b.name) ? 1 : -1 })),
  init () {
    this.theme = getTheme()
    applyTheme(this.theme)
    const loadHandler = async () => await this.load()
    loadHandler()
  },
  async load () {
    const taskService = new TaskService(
      new StatusRepository(),
      new TagRepository(),
      new TaskRepository(),
      new TaskStatusRepository(),
      new TaskTagRepository()
    )
    const tagService = new TagService(
      new TagRepository(),
      new StatusRepository()
    )
    const tasksResult = await taskService.getAll()
    this.tasks = tasksResult
    const tagsResult = await tagService.getAll()
    this.tags = tagsResult
    this.isLoading = false
  },
  toggleTheme () {
    if (this.theme === 'light') {
      this.theme = 'dark'
    } else if (this.theme === 'dark') {
      this.theme = 'light'
    }
    applyTheme(this.theme)
    localStorage.setItem(storageKey, this.theme)
  }
})
