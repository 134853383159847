<script setup>
import { reactive } from 'vue'
import 'emoji-picker-element'

const props = defineProps({
  unicode: {
    type: String
  }
})

const emit = defineEmits([
  'changeEmoji'
])

const data = reactive({ open: false })

const toggle = () => {
  console.log('toggle')
  data.open = !data.open
}

const clickEmoji = (detail) => {
  data.open = false
  emit('changeEmoji', detail)
}
</script>

<template>
  <div>
    <button @click="toggle">{{props.unicode || 'Emoji'}}</button>
    <div v-if="data.open">
      <emoji-picker
        @emoji-click="clickEmoji($event.detail)"
      />
    </div>
  </div>
</template>

<style scoped>
emoji-picker {
  --emoji-size: 1rem;
}
</style>
