<script setup>
const props = defineProps({
  open: {
    type: Boolean,
    default: false
  }
})
const handleToggle = (event) => {
  console.log('toggle', event, event.target.open)
}
</script>

<template>
  <details :open="props.open" @toggle="handleToggle($event)">
    <summary>summary</summary>
    <p>contents</p>
  </details>
</template>
