export default class TaskTag {
  constructor (
    id,
    taskId,
    tagId
  ) {
    this.id = id
    this.taskId = taskId
    this.tagId = tagId
  }

  toJSON () {
    return {
      id: this.id,
      taskId: this.taskId,
      tagId: this.tagId
    }
  }
}
